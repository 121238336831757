import { Panel } from '../Panel';
import { Grid, Skeleton } from '@mui/material';
import React from 'react';

export const TwoSidePanelSkeleton = () => <>
  <Panel>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
      </Grid>
    </Grid>
  </Panel>
</>;

export const PanelSkeleton = () => <>
  <Panel>
    <Skeleton variant="text"/>
    <Skeleton variant="text"/>
    <Skeleton variant="text"/>
    <Skeleton variant="text"/>
  </Panel>
</>;
