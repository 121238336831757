import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, Link, Skeleton } from '@mui/material';
import { Panel } from '../../components/common';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataTable, { COLUMN_TYPE } from '../../components/common/DataTable/DataTable';
import { PageToolbar } from '../../components/common/PageToolbar/PageToolbar';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import { Social } from '../../components/common/Social';
import { useQuery } from '@apollo/client';
import { GET_CRYPTO_EVENT_SOURCES } from '../../../../store/queries/cryptoEventSource';
import { CryptoEventSource } from '../../../../store/generated-models';

export const EventSources: FC<any> = (props) => {
  const navigate = useNavigate();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const [sources, setSources] = useState([]);

  const { data, loading, refetch } = useQuery(GET_CRYPTO_EVENT_SOURCES, {
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (loading) {
    } else {

      setSources(data?.getCryptoEventSources?.list || []);
    }
  }, [data]);

  const columns = [
    {
      code: 'title',
      label: 'Source name',
      render: (row: any) => {
        const title = row['title'],
          code = row['code'];

        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <Social socialCode={code}/>
          <span>{title}</span>
        </div>;
      },
      loadingRender: () => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 0, marginRight: '16px' }}>
            <Skeleton variant="circular" width={32} height={32}/>
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton variant="text"/>
          </div>
        </div>;
      }
    },
    {
      code: 'created',
      label: 'Creation Date',
      type: COLUMN_TYPE.DATE
    },
    {
      code: 'link',
      label: 'Source link',
      render: (row: any, column: any) => {
        const { link } = row;

        return <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link href={link}
                  underline="none" target="_blank" sx={{ fontSize: '12px' }}
                  onClick={(event) => event.stopPropagation()}
            >{link}</Link>
          </div>
        </>;
      }
    }
  ];

  const rowOnClick = (row: CryptoEventSource) => {
    const id = row.cryptoEventSourceId;

    if (id) {
      navigate(`/private/event-source-detailed/${id}`);
    }
  };

  const getToolbarContainerHeight = () => {
    let height = '48px';

    if (isPhone) {
      height = '112px';
    }

    return height;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ minHeight: getToolbarContainerHeight() }}>
        <PageToolbar
          leftSide={
            <>
              {
                !isPhone && <RouterLink to="/private/event-source-detailed" style={{ textDecoration: 'none' }}>
                  <Button variant="contained">Create source</Button>
                </RouterLink>
              }
              {
                isPhone && <div style={{ marginTop: '54px' }}>
                  <RouterLink to="/private/project-detailed" style={{ textDecoration: 'none', width: '100%' }}>
                    <Button variant="contained" style={{ height: '42px', width: 'calc(100vw - 32px)' }}>
                      Create source
                    </Button>
                  </RouterLink>
                </div>
              }
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Panel>
          <DataTable rows={sources}
                     columns={columns} isEditOnClick
                     rowOnClick={rowOnClick}
                     isWithPagination
                     isLoading={loading}
          />
        </Panel>
      </Grid>
    </Grid>
  );
};
