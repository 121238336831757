import {ReactComponent as GithubSvg} from '../../../../assets/github.svg';
import {ReactComponent as DiscordSvg} from '../../../../assets/discord.svg';
import {ReactComponent as TwitterSvg} from '../../../../assets/twitter.svg';
import {ReactComponent as InstagramSvg} from '../../../../assets/instagram.svg';
import {ReactComponent as YoutubeSvg} from '../../../../assets/youtube.svg';
import {ReactComponent as TelegramSvg} from '../../../../assets/telegram.svg';
import {ReactComponent as LinkedinSvg} from '../../../../assets/linkedin.svg';
import {ReactComponent as DollarSvg} from '../../../../assets/dollar.svg';
import {ReactComponent as UploadSvg} from '../../../../assets/upload.svg';
import {ReactComponent as ProjectsSvg} from '../../../../assets/projects.svg';
import {ReactComponent as RequestsSvg} from '../../../../assets/requests.svg';
import {ReactComponent as CheckboxCheckedSvg} from '../../../../assets/checkbox-checked.svg';
import {ReactComponent as CheckboxUncheckedSvg} from '../../../../assets/checkbox-unchecked.svg';
import {ReactComponent as ErrorSvg} from '../../../../assets/error.svg';
import {ReactComponent as StopSvg} from '../../../../assets/stop.svg';
import {ReactComponent as WarningSvg} from '../../../../assets/warning.svg';
import {ReactComponent as FileSvg} from '../../../../assets/file.svg';
import {ReactComponent as UserSvg} from '../../../../assets/user.svg';
import {ReactComponent as InvestorsSvg} from '../../../../assets/investors.svg';
import {ReactComponent as MailSvg} from '../../../../assets/mail.svg';
import {ReactComponent as BtcSvg} from '../../../../assets/currency/btc.svg';
import {ReactComponent as SolSvg} from '../../../../assets/currency/sol.svg';
import {ReactComponent as FilSvg} from '../../../../assets/currency/fil.svg';
import {ReactComponent as OceanSvg} from '../../../../assets/currency/ocean.svg';
import {ReactComponent as CusdSvg} from '../../../../assets/currency/cusd.svg';
import {ReactComponent as NmrSvg} from '../../../../assets/currency/nmr.svg';
import {ReactComponent as LinkCurrencySvg} from '../../../../assets/currency/link.svg';
import {ReactComponent as UsdtSvg} from '../../../../assets/currency/usdt.svg';
import {ReactComponent as CompSvg} from '../../../../assets/currency/comp.svg';
import {ReactComponent as XtzSvg} from '../../../../assets/currency/xtz.svg';
import {ReactComponent as CeloSvg} from '../../../../assets/currency/celo.svg';
import {ReactComponent as MkrSvg} from '../../../../assets/currency/mkr.svg';
import {ReactComponent as DaiSvg} from '../../../../assets/currency/dai.svg';
import {ReactComponent as OxtSvg} from '../../../../assets/currency/oxt.svg';
import {ReactComponent as AlgoSvg} from '../../../../assets/currency/algo.svg';
import {ReactComponent as WbtcSvg} from '../../../../assets/currency/wbtc.svg';
import {ReactComponent as UsdcSvg} from '../../../../assets/currency/usdc.svg';
import {ReactComponent as PaxSvg} from '../../../../assets/currency/pax.svg';
import {ReactComponent as Erc20Svg} from '../../../../assets/currency/erc20.svg';
import {ReactComponent as EtcSvg} from '../../../../assets/currency/etc.svg';
import {ReactComponent as BusdSvg} from '../../../../assets/currency/erc20.svg';
import {ReactComponent as LunaSvg} from '../../../../assets/currency/erc20.svg';
import {ReactComponent as AvaxSvg} from '../../../../assets/currency/erc20.svg';
import {ReactComponent as MaticSvg} from '../../../../assets/currency/erc20.svg';
import {ReactComponent as FlowSvg} from '../../../../assets/currency/erc20.svg';
import {ReactComponent as LinkSvg} from '../../../../assets/common/link_light.svg';
import {ReactComponent as LinkButtonSvg} from '../../../../assets/common/link.svg';
import {ReactComponent as CopySvg} from '../../../../assets/common/copy.svg';
import {ReactComponent as SignOutSvg} from '../../../../assets/common/sign_out.svg';
import {ReactComponent as VerifySvg} from '../../../../assets/common/verify.svg';
import {ReactComponent as SalesSvg} from '../../../../assets/common/sales.svg';
import {ReactComponent as SuitcaseSvg} from '../../../../assets/common/suitcase.svg';
import {ReactComponent as Filter} from '../../../../assets/common/filter.svg';
import {ReactComponent as Search} from '../../../../assets/common/search.svg';
import {ReactComponent as InvestmentPortfolio} from '../../../../assets/investment-portfolio.svg';
import {ReactComponent as Cards} from '../../../../assets/common/cards.svg';
import {ReactComponent as List} from '../../../../assets/common/list.svg';
import {ReactComponent as Info} from '../../../../assets/common/info.svg';
import {ReactComponent as Users} from '../../../../assets/common/users.svg';
import {ReactComponent as UserGroup} from '../../../../assets/common/users-profile-group.svg';
import {ReactComponent as Question} from '../../../../assets/question.svg';
import {ReactComponent as InvestmentPortfolioBlue} from '../../../../assets/common/investment-portfolio.svg';
import {ReactComponent as CryptoNotifier} from '../../../../assets/crypto-notifier.svg';
import {ReactComponent as GreenCheck} from '../../../../assets/green_check.svg';
import {ReactComponent as Wallet} from '../../../../assets/wallet.svg';
import {ReactComponent as Email} from '../../../../assets/email.svg';
import {ReactComponent as Alert} from '../../../../assets/alert.svg';
import {ReactComponent as CheckmarkInCircle} from '../../../../assets/checkmark-in-circle.svg';
import React from 'react';
import './CustomIcon.scss';

const iconMapping: Record<string, any> = {
  'file': <FileSvg/>,
  'user': <UserSvg/>,
  'github': <GithubSvg/>,
  'discord': <DiscordSvg/>,
  'twitter': <TwitterSvg/>,
  'instagram': <InstagramSvg/>,
  'youtube': <YoutubeSvg/>,
  'telegram': <TelegramSvg/>,
  'linkedin': <LinkedinSvg/>,
  'link': <LinkSvg/>,
  'linkButton': <LinkButtonSvg/>,
  'copy': <CopySvg/>,
  'upload': <UploadSvg/>,
  'dollar': <DollarSvg/>,
  'projects': <ProjectsSvg/>,
  'requests': <RequestsSvg/>,
  'investors': <InvestorsSvg/>,
  'checkboxChecked': <CheckboxCheckedSvg/>,
  'checkboxUnchecked': <CheckboxUncheckedSvg/>,
  'error': <ErrorSvg/>,
  'stop': <StopSvg/>,
  'BTC': <BtcSvg/>,
  'SOL': <SolSvg/>,
  'FIL': <FilSvg/>,
  'BUSD': <BusdSvg/>,
  'LUNA': <LunaSvg/>,
  'AVAX': <AvaxSvg/>,
  'MATIC': <MaticSvg/>,
  'FLOW': <FlowSvg/>,
  'OCEAN': <OceanSvg/>,
  'CUSD': <CusdSvg/>,
  'NMR':<NmrSvg/>,
  'LINK': <LinkCurrencySvg/>,
  'USDT':  <UsdtSvg/>,
  'COMP': <CompSvg/>,
  'XTZ': <XtzSvg/>,
  'CELO': <CeloSvg/>,
  'MKR': <MkrSvg/>,
  'DAI': <DaiSvg/>,
  'OXT': <OxtSvg/>,
  'ALGO': <AlgoSvg/>,
  'WBTC': <WbtcSvg/>,
  'USDC': <UsdcSvg/>,
  'PAX': <PaxSvg/>,
  'ERC20': <Erc20Svg/>,
  'ETH': <Erc20Svg/>,
  'ETC': <EtcSvg/>,
  'warning': <WarningSvg/>,
  'sign_out': <SignOutSvg/>,
  'verify': <VerifySvg/>,
  'mail': <MailSvg/>,
  'sales': <SalesSvg/>,
  'suitcase': <SuitcaseSvg/>,
  'investment_portfolio': <InvestmentPortfolio/>,
  'filter': <Filter/>,
  'search': <Search/>,
  'cards': <Cards/>,
  'list': <List/>,
  'info': <Info/>,
  'users': <Users/>,
  'user-group': <UserGroup/>,
  'question': <Question/>,
  'investment-portfolio-blue': <InvestmentPortfolioBlue/>,
  'crypto_notifier': <CryptoNotifier/>,
  'green-check': <GreenCheck/>,
  'wallet': <Wallet/>,
  'email': <Email/>,
  'alert': <Alert/>,
  'checkmark-in-circle': <CheckmarkInCircle/>
};

const Icon = (props: any) => {
  const {children, color} = props;

  return (
    <div className={`icon ${color ? 'icon--' + color : ''}`}>
      {children}
    </div>
  );
};

export const getIcon = (iconName: string, color: string = '') => {
  if (typeof iconMapping[iconName] !== 'undefined') {
    return <Icon color={color}>
      {iconMapping[iconName]}
    </Icon>;
  } else {
    return iconMapping.link;
  }
};
