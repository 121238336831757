import React, { FC, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CryptoAssetData } from './CryptoAssetData';
import { CryptoEventList } from './CryptoEventList';
import { useMutation, useQuery } from '@apollo/client';
import { CryptoEvent, CryptoAsset } from '../../../../../store/generated-models';
import { GET_MY_ASSET_BY_ID } from '../../../../../store/queries/users';
import { PanelSkeleton, TwoSidePanelSkeleton } from '../Skeletons/Skeletons';
import { GET_CRYPTO_EVENTS_BY_ASSET_ID } from '../../../../../store/queries/cryptoEvent';
import { MARK_VIEWED_BY_LINKED_OBJECTS } from '../../../../../store/mutations/notifications';
import { Panel } from '../Panel';

export const CryptoAssetDetailedView: FC<any> = (props) => {
  const { id } = useParams();
  const { isAdminPage } = props;
  const navigate = useNavigate();

  const [viewNotifications, {
    data: dataViewNotifications,
    loading: loadingViewNotifications,
    error: errorViewNotifications
  }] = useMutation(MARK_VIEWED_BY_LINKED_OBJECTS);

  const { data, loading } = useQuery(GET_MY_ASSET_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      cryptoAssetId: id
    },
    skip: !id
  });
  const [asset, setAsset] = useState<CryptoAsset>();

  useEffect(() => {
    if (!loading) {
      setAsset(data.getCryptoAssetById);
      viewNotifications({ variables: { linkedObjects: [id] } }).then();
    }
  }, [data]);

  const {
    data: eventsData,
    loading: eventsLoading,
    refetch: eventsRefetch
  } = useQuery(GET_CRYPTO_EVENTS_BY_ASSET_ID, {
    fetchPolicy: 'network-only',
    variables: {
      cryptoAssetId: id
    },
    skip: !id
  });
  const [events, setEvents] = useState<CryptoEvent[]>([]);

  useEffect(() => {
    if (!eventsLoading && eventsData?.getCryptoEventsByAssetId) {
      setEvents(eventsData.getCryptoEventsByAssetId.map((ev: CryptoEvent) => {
        return {
          title: ev.title,
          date: ev.pubDate,
          isFake: ev.isFake,
          socialTitle: ev.source.title,
          description: ev.description
        };
      }));
    }
  }, [eventsData]);

  const ScamAlertBlock = () => <Panel>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ marginBottom: '32px' }}>
        <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
          Scam Alert
        </Typography>
      </Grid>
      <Grid item xs={12}>
        We have received reports indicating that this asset is fraudulent.
        To prevent the loss of your actual assets, please refrain from engaging in any transactions or activities
        involving it.
      </Grid>
    </Grid>
  </Panel>;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {
          loading || !asset ?
            <Grid item xs={12}><TwoSidePanelSkeleton/></Grid> :
            <CryptoAssetData asset={asset} isAdminPage={isAdminPage}/>
        }
      </Grid>
      {
        loading || eventsLoading ?
          <Grid item xs={12}><PanelSkeleton/></Grid>
          :
          <Grid item xs={12}>
            {
              asset && asset.isScam
                ? <ScamAlertBlock/>
                : <CryptoEventList events={events}/>
            }
          </Grid>
      }
    </Grid>
  );
};
