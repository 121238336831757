import { gql } from '@apollo/client';

export const GET_CRYPTO_EVENTS_BY_ASSET_ID = gql`
  query getCryptoEventsByAssetId($cryptoAssetId: String) {
    getCryptoEventsByAssetId(cryptoAssetId: $cryptoAssetId) {
      title
      description
      isFake
      pubDate
      source {
        socialCode
        title
      }
    }
  }
`;
