import React, {useContext} from 'react';
import {Panel} from "../../components/common";
import {Button, Typography} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import config from "../../../../config";
import {AuthContext} from "../../../../core/providers/AuthProvider";

export const KycRestrictionPanel = (props: any) => {
  const { description } = props;
  const authContext = useContext(AuthContext);

  const defaultDescription = 'To enable Funds management and receiving Requests from Investors, we need to verify your identity';

  return <Panel>
    {
      !authContext.isVerified && <>
        <Typography sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '8px' }}>
          Please pass KYC verification to complete the account setup
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '24px' }}>
          {
            description || defaultDescription
          }
        </Typography>

        <Button
          variant="contained"
          endIcon={<ArrowForward/>}
          onClick={(e: any) => {
            if (authContext.blockpassWidget) {
              authContext.blockpassWidget._onBtnClickHandler(e);
            }
          }}
        >
          Verify KYC
        </Button>
      </>
    }
    {
      authContext.isVerified && <>
        <Typography sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '8px' }}>
          Please finalize your account setup by passing KYB verification
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '24px' }}>
          {
            description || defaultDescription
          }
        </Typography>

        <Button
          variant="contained"
          endIcon={<ArrowForward/>}
          onClick={() => window.open(config.kybUrl, '_blank')}
        >
          Verify KYB
        </Button>
      </>
    }
  </Panel>;
};
