import React, { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROJECTS } from '../../../../store/queries/projects';
import { GET_PROJECTS_WITH_SUMMARY } from '../../../../store/queries/projects';
import { Box, Button, Chip, Grid, Link, Skeleton, Typography } from '@mui/material';
import { Project, ProjectStatusEnum } from '../../../../store/generated-models';
import DataTable, { COLUMN_TYPE } from '../../components/common/DataTable/DataTable';
import { ComplexAvatar } from '../../components/common/ComplexAvatar';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Social } from '../../components/common/Social';
import { Panel } from '../../components/common';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { Filters } from '../../components/common/DataTable/Filters';
import { Search } from '../../components/common/DataTable/Search';
import { useFilters } from '../../hooks/useFilters';
import { PageToolbar } from '../../components/common/PageToolbar/PageToolbar';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';

export const Projects: FC<any> = (props: any) => {
  const { toolbarItem } = props;

  const authContext = useContext(AuthContext);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { queryVariables, onFilterChange, onSearchChange } = useFilters();

  const { data, refetch, loading } = useQuery(authContext.isAdmin ? GET_PROJECTS_WITH_SUMMARY : GET_PROJECTS, {
    variables: {
      filterEx: queryVariables.queryFilters,
      orderBy: queryVariables.sorters
    },
    fetchPolicy: 'network-only'
  });
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
    } else {
      if (authContext.isAdmin) {
        setProjects(data?.getProjectsWithSummary?.list || []);
      } else {
        setProjects(data?.getProjects?.list || []);
      }
    }
  }, [data]);

  const columns = [
    {
      code: 'title',
      label: 'Project',
      width: '400px',
      render: (row: any, column: any) => {
        const title = row['title'],
          avatar = row['avatar'],
          link = avatar ? `${process.env.REACT_APP_API_URL_REST}${avatar}` : '';

        return <ComplexAvatar width={32} height={32} src={link} label={title}/>;
      },
      loadingRender: () => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 0, marginRight: '16px' }}>
            <Skeleton variant="circular" width={32} height={32}/>
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton variant="text"/>
          </div>
        </div>;
      }
    },
    {
      code: 'tokenGenerationDate',
      label: 'TGE Date',
      width: '150px',
      emptyPlaceholder: 'Coming soon',
      type: COLUMN_TYPE.DATE,
      filter: {
        type: 'date'
      }
    },
    {
      code: 'tokensTotal',
      label: 'Tokens',
      width: '200px',
      type: COLUMN_TYPE.NUMERIC,
      emptyPlaceholder: 'N/A'
    },
    {
      code: 'roundsTotal',
      label: 'Rounds',
      width: '50px',
      contentAlign: 'center',
      emptyPlaceholder: 'N/A'
    },
    {
      code: 'whitepaperLink',
      label: '',
      width: '200px',
      contentAlign: 'center',
      render: (row: any, column: any) => {
        const value = row[column.code];

        return value ?
          <Link href={value}
                onClick={(event) => event.stopPropagation()}
                target="_blank" underline="hover"
          ><Typography variant="subtitle2">White paper</Typography></Link> :
          <Typography variant="subtitle2" sx={{ color: '#BBBDC0' }}>White paper</Typography>;
      }
    },
    {
      code: 'socials',
      label: '',
      render: (row: any, column: any) => {
        const socials = row[column.code];

        return socials && <div className="socials">
          {
            socials.map((socialData: Record<string, any>, idx: number) => <React.Fragment key={idx}>
                <Social key={idx} socialCode={socialData.social.socialCode} href={socialData.href}/>
              </React.Fragment>
            )
          }
        </div>;
      }
    },
    {
      code: 'status',
      label: '',
      width: '65px',
      render: (row: any, column: any) => {
        const value = row[column.code];

        return <Chip label={value === ProjectStatusEnum.Inactive ? 'Past' : 'Active'}
                     sx={{
                       color: value === ProjectStatusEnum.Inactive ? '#E2507A' : '#2BB596', width: '65px',
                       backgroundColor: value === ProjectStatusEnum.Inactive ? '#FAE2E9' : '#D6F5EE'
                     }}
        />;
      },
      sorter: {
        label: 'Status',
        options: [
          {
            label: 'Active',
            value: false
          },
          {
            label: 'Past',
            value: true
          }
        ]
      }
    }
  ];

  const rowOnClick = (row: Project) => {
    const id = row.projectId;

    if (id) {
      navigate(`/private/project-detailed/${id}`);
    }
  };

  useEffect(() => {
    refetch();
  }, [queryVariables]);

  const filterBlockStyles: any = {
    display: 'flex',
    justifyContent: !isMobile ? 'end' : 'normal',
    flex: 1,
    marginLeft: isPhone ? 'inherit' : 'auto',
    position: isMobile ? 'fixed' : 'inherit',
    right: isPhone ? '16px' : '24px',
    left: isPhone ? '16px' : 'auto'
  };

  const getToolbarContainerHeight = () => {
    let height = '48px';

    if (isPhone) {
      height = '112px';
    }

    return height;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ minHeight: getToolbarContainerHeight() }}>
          <PageToolbar
            leftSide={
              <>
                {
                  !isPhone && <RouterLink to="/private/project-detailed" style={{ textDecoration: 'none' }}>
                    <Button variant="contained">Create project</Button>
                  </RouterLink>
                }
                <div style={filterBlockStyles}>
                  <Filters onChange={onFilterChange} columns={columns}/>
                  <Search placeholder="Search project" onChange={onSearchChange} field="title"/>
                  {isMobile && toolbarItem}
                </div>
                {
                  isPhone && <div style={{ marginTop: '54px' }}>
                    <RouterLink to="/private/project-detailed" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button variant="contained" style={{ height: '42px', width: 'calc(100vw - 32px)' }}>
                        Create project
                      </Button>
                    </RouterLink>
                  </div>
                }
              </>
            }
            rightSide={!isMobile && toolbarItem}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel>
            {
              <DataTable rows={projects} columns={columns} isEditOnClick rowOnClick={rowOnClick} isWithPagination
                         isLoading={loading}/>
            }
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
