import { Box, Button, Chip, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { dateFormat, numberFormat } from '../../../../../core/helpers/utils';
import { Social } from '../Social';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Panel } from '../Panel';
import { useMutation } from "@apollo/client";
import { UPDATE_CRYPTO_ASSET } from "../../../../../store/mutations/cryptoAsset";

export const CryptoAssetData = (props: any) => {
  let { asset, isAdminPage } = props;
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isScam, setIsScam] = useState(asset.isScam);
  const navigate = useNavigate();

  const [updateCryptoAsset, {
    data: dataUpdate,
    loading: loadingProjectUpdate,
    error: errorProjectUpdate
  }] = useMutation(UPDATE_CRYPTO_ASSET);

  const handleSetIsScam = async (event: any) => {
    await updateCryptoAsset({
      variables: {
        cryptoAssetId: asset.cryptoAssetId,
        isScam: event.target.checked
      }
    }).then((res) => {
      setIsScam(!isScam);
      enqueueSnackbar('Saved', { variant: 'success' });
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  const handleCancel = () => {
    if (isAdminPage) {
      navigate('/private/crypto-assets');
    } else {
      navigate('/private/wallets');
    }
  };

  return (
    <Panel>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sx={{ marginBottom: '32px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: '16px'
            }}
          >
            <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500, marginRight: '8px'}}>
              {asset.symbol}
            </Typography>
            <Chip label={isScam ? 'Scam' : 'Not Scam'}
                  sx={{
                    height: '16px',
                    fontSize: '9px',
                    color: isScam ? '#E2507A' : '#2BB596',
                    backgroundColor: isScam ? '#FAE2E9' : '#D6F5EE'
                  }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={5}>
              <Box>
                <Grid container>
                  <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="viewModeLabel" color="text.secondary" component="span">
                          Asset name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="viewModeValue">
                          {asset.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {
                    !isAdminPage &&
                    <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                      <Grid container columnSpacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="viewModeLabel" color="text.secondary" component="span">
                            Number of events
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="viewModeValue">
                            {asset.statistics?.numberOfEvents ? `${numberFormat(asset.statistics.numberOfEvents)}` : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }

                  {
                    authContext.isAdmin && isAdminPage &&
                    <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                      <Grid container columnSpacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="viewModeLabel" color="text.secondary" component="span">
                            Followers
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="viewModeValue">
                            {asset.followers ? `${numberFormat(asset.followers)}` : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={0} sm={2}>
              <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
                <div style={{ width: '1px', borderLeft: '1px solid #D6D6D6' }}/>
              </div>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Box>
                <Grid container>
                  <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="viewModeLabel" color="text.secondary" component="span">
                          Social links
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="viewModeValue">
                          {
                            asset.socials && asset.socials.length > 0 ? <div className="socials">
                              {
                                asset.socials.map((socialData: Record<string, any>, idx: number) => <React.Fragment
                                    key={idx}>
                                    <Social key={idx} socialCode={socialData.social.socialCode} href={socialData.href}/>
                                  </React.Fragment>
                                )
                              }
                            </div> : "N/A"
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="viewModeLabel" color="text.secondary" component="span">
                          Last event
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="viewModeValue">
                          {asset.statistics.lastEventDate ? dateFormat(asset.statistics.lastEventDate) : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {
              authContext.isAdmin && isAdminPage && <>
                <Grid item sm={6} xs={12}>
                  <FormControlLabel
                    disabled={false}
                    control={
                      <Switch name="status" checked={!!isScam} onChange={handleSetIsScam}/>
                    }
                    label="Scam"
                  />
                </Grid>
              </>
            }
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, paddingTop: '48px' }}>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  );
};
