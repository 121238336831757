import React from 'react';
import Typography from '@mui/material/Typography';
import './EmptyState.scss';

export const EmptyState = (props: any) => {
  const { Image, imgMargin = 0, title, titleMargin = 0, description, descriptionColor = '#BBBDC0' } = props;

  return <div className="empty">
    <Image style={{ marginBottom: imgMargin }}/>
    <Typography variant="h6" sx={{ marginBottom: titleMargin }}>{title}</Typography>
    <Typography sx={{ color: descriptionColor }}>{description}</Typography>
  </div>;
};
