import React from 'react';
import { Grid, Typography } from '@mui/material';
import { CryptoEvent } from './CryptoEvent';
import { Panel } from '../Panel';

export const CryptoEventList = (props: any) => {
  const { events } = props;

  return <Panel>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ marginBottom: '32px' }}>
        <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
          Recent Events
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
          events.length > 0
            ? events.map((event: any, index: number) => <CryptoEvent key={index} data={event}/>)
            : 'Either you haven\'t subscribed to this asset or\n we haven\'t discovered any relevant events for it at this time'
        }
      </Grid>
    </Grid>
  </Panel>;
};
