import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import { useQuery, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  GET_PARTICIPATION_REQUEST_BY_ID,
  PARTICIPATION_REQUEST_SUBSCRIPTION
} from '../../../../store/queries/requests';
import {
  ProjectPRequest,
  ParticipationRequestStatusEnum
} from '../../../../store/generated-models';
import { TokensAllocation } from '../../components/common/business/TokensAllocation/TokensAllocation';
import { TransactionList } from '../../components/common/business/History/TransactionList';
import { Panel, RequestSummary } from '../../components/common/';
import { CurrentSAFT } from '../../components/common/business/CurrentSAFT/CurrentSAFT';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import { RequestProgress } from '../../components/common/business/RequestProgress/RequestProgress';

export const ParticipationRequestDetailed = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { data, loading, refetch } = useQuery(GET_PARTICIPATION_REQUEST_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      participationRequestId: id
    }
  });
  const [request, setRequest] = useState<ProjectPRequest>();
  const [isRefetching, setIsRefetching] = useState(false);

  const { data: subscriptionData, loading: subscriptionLoading } = useSubscription(
    PARTICIPATION_REQUEST_SUBSCRIPTION, { variables: { subscriptionEventIds: [id] } }
  );

  useEffect(() => {
    if (!loading) {
      setRequest(data.getProjectPRequestById);
    }
  }, [data]);

  const isDataReady = request && request.projectPRequestId;
  const isRequestProgressVisible = isDataReady;
  const isRequestSummaryVisible = isDataReady;
  const isTokensAllocationVisible = isDataReady && (
    [ParticipationRequestStatusEnum.Approved, ParticipationRequestStatusEnum.Novation].includes(request.status)
    || authContext.isAdmin && request.status === ParticipationRequestStatusEnum.Pending
  ) && (authContext.isAdmin || !request.isLegalPerson);
  const isCurrentSAFTVisible = isDataReady && request.contract && [ParticipationRequestStatusEnum.Approved, ParticipationRequestStatusEnum.Novation].includes(request.status);
  const isHistoryVisible = isDataReady && request.userActionLog.length > 0;

  const TwoSidePanelSkeleton = () => <>
    <Panel>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
        </Grid>
      </Grid>
    </Panel>
  </>;

  const PanelSkeleton = () => <>
    <Panel>
      <Skeleton variant="text"/>
      <Skeleton variant="text"/>
      <Skeleton variant="text"/>
      <Skeleton variant="text"/>
    </Panel>
  </>;

  return (
    <>
      {
        <Box sx={{ maxWidth: '1600px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={loading || request?.userActionLog?.length > 0 ? 9 : 12}>
              <Grid container spacing={3}>
                {
                  authContext.isUser && <>
                    {
                      loading ?
                        <Grid item xs={12}><PanelSkeleton/></Grid>
                        :
                        isRequestProgressVisible && <Grid item xs={12}>
                          <RequestProgress request={request} refetch={refetch} isRefetching={isRefetching} setIsRefetching={setIsRefetching}/>
                        </Grid>
                    }
                  </>
                }
                {
                  <>
                    {
                      loading ?
                        <Grid item xs={12}><TwoSidePanelSkeleton/></Grid>
                        :
                        isRequestSummaryVisible && <Grid item xs={12}>
                          <RequestSummary request={request}/></Grid>
                    }
                  </>
                }
                {
                  <>
                    {
                      loading ?
                        <Grid item xs={12}><TwoSidePanelSkeleton/></Grid>
                        :
                        isTokensAllocationVisible && <Grid item xs={12}>
                          <TokensAllocation request={request} refetch={refetch}/>
                        </Grid>
                    }
                  </>
                }
                {
                  <>
                    {
                      loading ?
                        <Grid item xs={12}><TwoSidePanelSkeleton/></Grid>
                        :
                        isCurrentSAFTVisible && <Grid item xs={12}>
                            <CurrentSAFT request={request} refetch={refetch} isRefetching={isRefetching} setIsRefetching={setIsRefetching}/>
                        </Grid>
                    }
                  </>
                }
              </Grid>
            </Grid>
            {
              <Grid item xs={12} xl={3}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      loading ?
                        <PanelSkeleton/>
                        :
                        isHistoryVisible && <TransactionList request={request}/>
                    }
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Box>
      }
    </>
  );
};
