import React, { FC, useContext, useState } from 'react';
import { InvestorDetailed } from '../../../user/investorDetailed/InvestorDetailed';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { FundOwnerDetailed } from './FundOwnerDetailed';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../TabPanel/TabPanel';
import { Security } from '../../../user/settings/Security';

export const Profile: FC<any> = (props) => {
  const authContext = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Tabs
            value={activeTab}
            onChange={handleActiveTabChange}
            aria-label="secondary tabs example"
          >
            <Tab value={0} label="Password" sx={{ textTransform: 'none' }}/>
            <Tab value={1} label="Security" sx={{ textTransform: 'none' }}/>
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={activeTab} index={0}>
          {
            authContext.isFundOwner && <FundOwnerDetailed {...props}/>
          }
          {
            !authContext.isFundOwner && <InvestorDetailed {...props}/>
          }
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Security/>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
