import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { dateFormat, numberFormat } from 'core/helpers/utils';
import { ReactComponent as EmptyStateImage } from '../../../../../assets/common/empty_state.svg';
import { Skeleton } from '@mui/material';
import './DataTable.scss';
import { EmptyState } from '../EmptyState/EmptyState';

export enum COLUMN_TYPE {
  'NUMERIC',
  'DATE'
}

export type DataTableColumn = {
  code?: string,
  label?: string,
  isHidden?: boolean,
  width?: string,
  align?: 'center' | 'left' | 'right',
  contentAlign?: 'center' | 'left' | 'right',
  emptyPlaceholder?: string,
  type?: COLUMN_TYPE,
  render?: Function,
  style?: any,
  filter?: any,
  sorter?: any
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

interface EnhancedTableProps {
  columns: any[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isWithSelection: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { columns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isWithSelection } =
    props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {
          isWithSelection && <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </TableCell>
        }
        {columns.filter(column => !column.isHidden).map((column: any, index: number) => (
          <TableCell
            key={index}
            align={column.align || 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
          >
            {
              column.isSortable ?
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel> :
                <span className={'table__header'}>
                  {column.label}
                </span>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle2"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon/>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon/>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

interface DataTableProps {
  rows: any[];
  columns: any[];
  isWithSelection?: boolean;
  isWithPagination?: boolean;
  isEditOnClick?: boolean;
  isLoading?: boolean;
  rowOnClick?: Function;
  rowsPerPage?: number;
}

export default function DataTable(props: DataTableProps) {
  const { rows, columns, isWithSelection, isWithPagination, isEditOnClick, isLoading, rowOnClick } = props;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage || 10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowSelection = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    rowOnClick(row);
    if (isWithSelection) {
      handleRowSelection(event, row.name);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const drawCell = (row: any, column: any, isLoading: boolean) => {
    let value = isLoading ? null : row[column.code];
    let cellContent = null;

    if (!value) {
      value = column.emptyPlaceholder || '';
    } else if (column.type === COLUMN_TYPE.NUMERIC) {
      value = numberFormat(value);
    } else if (column.type === COLUMN_TYPE.DATE) {
      value = dateFormat(value);
    }

    if (isLoading) {
      if (column.loadingRender) {
        cellContent = column.loadingRender();
      } else {
        cellContent = <Skeleton variant="text"/>;
      }
    } else {
      cellContent = column.render ? column.render(row, column) : <Typography variant="body1">{value}</Typography>;
    }

    return cellContent;
  };

  const rawsToDraw = !isLoading ? rows.slice().sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : new Array(rowsPerPage).fill(null).map((_, i) => i + 1);

  return (
    <Box sx={{ width: '100%' }}>
      {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
      <TableContainer>
        <Table
          className="table"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length || rowsPerPage}
            isWithSelection={isWithSelection}
          />
          <TableBody>
            {
              rawsToDraw.map((row: any, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={`table__row ${isEditOnClick ? 'table__row--clickable' : ''}`}
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    {
                      isWithSelection && <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                    }
                    {/*<TableCell*/}
                    {/*  component="th"*/}
                    {/*  id={labelId}*/}
                    {/*  scope="row"*/}
                    {/*  padding="none"*/}
                    {/*>*/}
                    {/*  {row.name}*/}
                    {/*</TableCell>*/}
                    {
                      columns.filter(column => !column.isHidden).map((column: any, index: number) => (
                          <TableCell className="table__cell" width={column.width} align={column.contentAlign || 'left'}
                                     sx={column.style}
                                     key={index}
                                     onClick={(event) => {
                                       if (column.isClickDisabled) {
                                         event.stopPropagation();
                                       }
                                     }}
                          >
                            {drawCell(row, column, isLoading)}
                          </TableCell>
                        )
                      )
                    }
                  </TableRow>
                );
              })}
            {
              rawsToDraw.length === 0 && <tr>
                <td colSpan={columns.length}>
                  <EmptyState Image={EmptyStateImage} title="No data yet"
                              description="There is no available information in this table yet"
                  />
                </td>
              </tr>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        isWithPagination && <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Box>
  );
}
