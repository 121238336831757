import { gql } from '@apollo/client';

export const GET_SUBSCRIPTIONS = gql`
  query getRecurringSubscriptions {
    getRecurringSubscriptions {
      list {
        id
        title
        price
        listItems
        maxCryptoAssetsToFollow
      }
    }
  }
`;

export const GET_MY_SUBSCRIPTIONS = gql`
  query getRecurringSubscriptions {
    myRecurringSubscriptions {
      subscriptionId
      dueDate
      isActive
    }
  }
`;

export const GET_BILLING_PORTAL_URL = gql`
  query getBillingPortalUrl {
    getBillingPortalUrl
  }
`;
