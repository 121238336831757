import { gql } from '@apollo/client';

export const GET_CRYPTO_EVENT_SOURCES = gql`
  query getCryptoEventSources {
    getCryptoEventSources {
      list {
        cryptoEventSourceId
        title
        code
        link
        created
      }
    }
  }
`;

export const GET_CRYPTO_EVENT_SOURCE_BY_ID = gql`
  query getCryptoEventSourceById($cryptoEventSourceId: String) {
    getCryptoEventSourceById(cryptoEventSourceId: $cryptoEventSourceId) {
      cryptoEventSourceId
      title
      code
      link
      created
    }
  }
`;
