import {gql} from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($user: UserInput, $password: String, $wallets: WalletDataMutationListInput) {
    createUser(user: $user, password: $password, wallets: $wallets) {
      userId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: ID!, $user: UserInput, $password: String, $wallets: WalletDataMutationListInput) {
    updateUser(userId: $userId, user: $user, password: $password, wallets: $wallets) {
      userId
    }
  }
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($wallets: WalletDataMutationListInput, $user: UserInput, $password: PasswordInput) {
    updateMe(wallets: $wallets, user: $user, password: $password) {
      userId
      email
    }
  }
`;

export const UPDATE_WALLETS = gql`
  mutation UpdateMe($wallets: WalletDataMutationListInput) {
    updateMe(wallets: $wallets) {
      wallets {
          walletId
          ref
          link
          createdAt
          deleted
          processingStatusCode
      }
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($userId: ID!) {
    deactivateUser(userId: $userId) {
      userId
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($userId: ID!) {
    activateUser(userId: $userId) {
      userId
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      userId
    }
  }
`;

