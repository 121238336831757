import React, { FC, MouseEvent, useCallback, useContext, useState } from 'react';
import {
  Avatar,
  Box,
  Button, Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid, LinearProgress,
  Link, Skeleton, Tooltip, tooltipClasses, TooltipProps,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import avatarDefault from '../../../../assets/image-default.svg';
import './ProjectCard.scss';
import { dateFormat, numberFormat } from '../../../../core/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { Social } from '../../components/common/Social';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { ProjectStatusEnum } from '../../../../store/generated-models';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import { ExpandMore } from '../../components/common/ExpandMore/ExpandMore';

export const ProjectCard: FC = (props: any) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { isLoading } = props;

  const handleParticipateClick = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    navigate(`participation-request/${props.projectId}`);
  }, []);

  const handleExpandClick = (_e: MouseEvent) => setExpanded(!expanded);

  let { socials, rounds } = props;
  if (!socials || typeof socials === 'undefined') {
    socials = [];
  }

  const socialsContent = socials?.map((socialData: Record<string, any>, idx: number) => <React.Fragment key={idx}>
    <Social socialCode={socialData.social.socialCode} href={socialData.href}/>
  </React.Fragment>);

  if (!rounds) {
    rounds = [];
  }

  let expandMore;
  if (rounds.length) {
    expandMore = (
      <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      />
    );
  }

  let whitePaper;
  if (props.whitepaperLink && props.whitepaperLink.length) {
    const marginTop = socialsContent.length > 0 ? '12px' : 0;
    whitePaper = (
      isLoading ?
        <Skeleton variant="text" width={100}/>
        :
        <div style={{ marginTop: marginTop }}>
          <Link href={props.whitepaperLink} underline="hover"
                sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '22px', marginTop: marginTop }}
          >
            White paper
          </Link>
        </div>
    );
  }

  const cardWidth = 368;
  let cardStyles: any = {
    maxWidth: `${cardWidth}px`
  };
  if (isPhone) {
    cardStyles = {
      maxWidth: `100%`
    };
  }

  const LinerProgressStyled = styled(LinearProgress)`
  .MuiLinearProgress-dashed {
    background-image: none;
    animation: none;
    background-color: #E1E2E3;
  }
`;

  const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} followCursor classes={{ popper: className }}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(0, 0, 0, 0.54)'
    }
  }));

  return (
    <Card variant="outlined" sx={cardStyles}>
      <CardHeader
        avatar={
          isLoading ?
            <Skeleton variant="circular" width={60} height={60}/>
            :
            <Avatar sx={{ width: 60, height: 60 }}
                    src={props.avatar ? `${process.env.REACT_APP_API_URL_REST}${props.avatar}` : ''}>
              <img src={avatarDefault}/>
            </Avatar>
        }
        title={
          isLoading ?
            <Skeleton variant="text"/>
            :
            <Typography variant="h5" component="span" sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '30px',
              fontStyle: 'normal'
            }}
            >
              {props.title}
            </Typography>
        }
        subheader={
          isLoading ?
            <Skeleton variant="text"/>
            :
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#BBBDC0'
              }}
              gutterBottom
              variant="body2"
              color="text.secondary"
              component="div"
            >
              Token Generation
              Event: {props.tokenGenerationDate ? dateFormat(props.tokenGenerationDate) : 'Coming soon'}
            </Typography>
        }
      />
      {
        <CardContent>
          {
            isLoading ? <>
                <Skeleton variant="rectangular" width={334} height={118}/>
                <Skeleton variant="text" width={100}/>
              </>
              :
              (props.description || socialsContent.length > 0) &&
              <Box sx={{ bgcolor: 'text.primary, color: background.paper' }}>
                <Typography sx={{ fontSize: 14, whiteSpace: 'pre-line', marginBottom: '0px' }} color="text.secondary"
                            gutterBottom>
                  {props.description}
                </Typography>
                {socialsContent.length > 0 && whitePaper}
              </Box>
          }
        </CardContent>
      }
      <CardActions sx={{
        justifyContent: 'space-between',
        padding: '0 16px',
        marginBottom: '16px'
      }}>
        {
          isLoading ? <div className="socials">
              <Skeleton variant="rectangular" width={24} height={24} sx={{ mr: 1 }}/>
              <Skeleton variant="rectangular" width={24} height={24} sx={{ mr: 1 }}/>
              <Skeleton variant="rectangular" width={24} height={24} sx={{ mr: 1 }}/>
            </div>
            :
            (socialsContent.length > 0 || whitePaper) &&
            (
              <div className="socials">
                {socialsContent.length > 0 ? socialsContent : whitePaper}
              </div>
            )
        }
        {expandMore}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {
            rounds.map((round: any, index: number) => {
              const statistics = round.statistics || {};
              let soldPercent = statistics.tokensSold ? statistics.tokensSold / round.tokenCount * 100 : 0;
              let approvedPercent = statistics.tokensApproved ? statistics.tokensApproved / round.tokenCount * 100 : 0;
              const roundsMarginBottom = index < rounds.length - 1 ? '16px' : 0;

              if (soldPercent > 100) soldPercent = 100;
              if (approvedPercent > 100) approvedPercent = 100;

              return (
                <Grid container key={round.projectRoundId} sx={{ marginBottom: roundsMarginBottom }} rowSpacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1"
                                color="#000"
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500
                                }}
                    >
                      {round.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1"
                                color="#BBBDC0"
                                align="center"
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500
                                }}
                    >
                      {numberFormat(round.tokenCount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1"
                                color="#000"
                                align="right"
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '16px'
                                }}
                    >
                      ${numberFormat(round.price, {precision: 4})}
                    </Typography>
                  </Grid>
                  {
                    authContext.isAdmin && <Grid item xs={12}>
                      <DarkTooltip title={numberFormat(statistics.tokensSold ? statistics.tokensSold : 0)}>
                        <LinerProgressStyled color="primary" variant="buffer"
                                             value={soldPercent}
                                             valueBuffer={approvedPercent}/>
                      </DarkTooltip>
                      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px 0' }}>
                        <Typography variant="body1"
                                    color="#BBBDC0"
                                    align="center"
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: 500
                                    }}
                        >
                          Sales Progress
                        </Typography>
                        <Typography variant="body1"
                                    color="#BBBDC0"
                                    align="center"
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: 500
                                    }}
                        >
                          {numberFormat(soldPercent)}%
                        </Typography>
                      </div>
                    </Grid>
                  }
                  {
                    authContext.isUser && <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                  }
                </Grid>
              );
            })
          }
        </CardContent>
      </Collapse>
      {
        authContext.isUser && <CardContent sx={{ paddingTop: '24px' }}>
          <Button variant="contained" onClick={handleParticipateClick}
                  disabled={authContext.isAdmin || props.status !== ProjectStatusEnum.Active || !authContext.isVerified}
                  sx={{
                    width: '100%',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '24px'
                  }}>
            Participate
          </Button>
        </CardContent>
      }
    </Card>
  );
};
