import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid, Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { crudStatus } from '../../../../../core/constants/common';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useMutation, useQuery } from '@apollo/client';
import { GET_MY_WALLETS } from '../../../../../store/queries/users';
import { useForm } from '../../../hooks/useForm';
import { MutationUpdateMeArgs, Wallet } from '../../../../../store/generated-models';
import { normalizeWallets } from '../../../helpers/Normalize';
import { UPDATE_WALLETS } from '../../../../../store/mutations/users';
import { useSnackbar } from 'notistack';
import { Panel } from '../Panel';
import './Wallets.scss';
import { GraphQLErrors } from '@apollo/client/errors';
import { v4 as createUUID } from 'uuid';

export const Wallets: FC<any> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: myData, loading: myDataLoading, refetch: refetchMyData } = useQuery(GET_MY_WALLETS, {
    fetchPolicy: 'network-only'
  });

  const [updateMe, {
    data: walletsUpdateData,
    loading: loadingWalletsUpdate,
    error: walletUpdateError
  }] = useMutation(UPDATE_WALLETS, { fetchPolicy: 'no-cache' });

  type ProjectForm = {
    wallets: any[];
  }

  const initialValues: ProjectForm = {
    wallets: []
  };

  const validationConfig = {
    wallets: {
      type: 'wallet'
    }
  };

  const {
    data: formData, setData, errors, handleSubmit, handleAddListItem, handleChangeListItem, handleDeleteListItem
  } = useForm<ProjectForm>({ validationConfig, initialValues, onSubmit: () => onSubmit(formData) });
  const walletErrors = errors.wallets;
  const [walletServerErrors, setWalletServerErrors] = useState<Record<string, boolean>>({});

  const hasError = (walletId: any, index: number) => {
    const hasFormError = walletErrors && walletErrors[index] && walletErrors[index].link && walletErrors[index].link.length > 0;
    const hasServerError = walletServerErrors[walletId];

    return hasFormError || hasServerError;
  };

  const onSubmit = (data: any) => {
    const { wallets } = data;

    let variables: MutationUpdateMeArgs = {};

    if (wallets) {
      variables.wallets = {
        created: normalizeWallets(wallets, crudStatus.CREATED),
        updated: normalizeWallets(wallets, crudStatus.UPDATED),
        deleted: normalizeWallets(wallets, crudStatus.DELETED)
      };
    }

    updateMe({
      variables
    }).then((result: any) => {
      const processedWallets = result.data?.updateMe?.wallets;

      updateId(processedWallets);
      setWalletServerErrors({});
      enqueueSnackbar('Saved', { variant: 'success' });
    }).catch((error: any) => {
    });
  };

  const updateId = (newData: any) => {
    let createdWalletsMap: any = {};

    newData.forEach((wallet: Wallet) => {
      if (wallet.walletId != wallet.ref) {
        createdWalletsMap[wallet.ref] = wallet.walletId;
      }
    });

    setData({
      ...formData,
      wallets: formData.wallets.map((wallet: any) => {
        if (createdWalletsMap[wallet.walletId]) {
          wallet.walletId = createdWalletsMap[wallet.walletId];
          wallet.status = '';
        }

        return wallet;
      })
    });
  };

  useEffect(() => {
    if (walletUpdateError) {
      const errorsArray: GraphQLErrors = walletUpdateError.graphQLErrors;
      const individualErrors: any = errorsArray[0].extensions.individualErrors;
      const partialData: any = errorsArray[0].extensions.data;

      if (individualErrors) {
        const newErrors: Record<string, boolean> = {};

        setWalletServerErrors({});
        individualErrors.forEach((error: any) => {
          const ErrorElement = <div>
            <div style={{ margin: '4px 0' }}>{error.msg}</div>
            <div>{error.extraMsg}</div>
          </div>;

          newErrors[error.ref] = true;
          enqueueSnackbar(ErrorElement, { ...{ variant: 'error' } });
        });
        setWalletServerErrors({
          ...walletServerErrors,
          ...newErrors
        });
      } else {
        enqueueSnackbar(errorsArray[0].message, { ...{ variant: 'error' } });
      }

      if (partialData) {
        updateId(partialData);
      }
    }
  }, [walletUpdateError]);

  useEffect(() => {
    let savedUserData: typeof formData = { ...formData };

    if (myData) {
      let user = myData.me;

      for (const formField in savedUserData) {
        savedUserData[formField] = user[formField];
      }
      setData(savedUserData);
    }
  }, [myData]);

  const walletsSkeleton = <>
    <Grid item xs={12}>
      <Skeleton variant="text"/>
    </Grid>
    <Grid item xs={12}>
      <Skeleton variant="text"/>
    </Grid>
    <Grid item xs={12}>
      <Skeleton variant="text"/>
    </Grid>
  </>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Panel>
        <div className="form">
          <div className="form__body">
            <Grid container spacing={{ md: 4, xs: 2 }}>
              <Grid item xs={12}>
                <Typography variant="h6">Wallet Addresses</Typography>
              </Grid>

              <Grid item xs={12}>
                <div className="guide__item">
                  <Typography className="guide__title">Step 1.</Typography>
                  <Typography>
                    Add the correct wallet address(es) from which you would like us to retrieve your crypto assets.
                  </Typography>
                </div>
                <div className="guide__item">
                  <Typography className="guide__title">Step 2.</Typography>
                  <Typography>
                    Go to the Crypto Assets tab to choose the assets you wish to subscribe for possible crypto events.
                  </Typography>
                </div>
                <div className="guide__item">
                  <Typography className="guide__title">Step 3.</Typography>
                  <Typography>
                    You will receive event notifications via email or find them displayed in the Crypto Assets tab.
                  </Typography>
                </div>
              </Grid>
              {
                myDataLoading && walletsSkeleton
              }
              {
                !myDataLoading && <Grid item xs={12}>
                  <>
                    {
                      formData?.wallets?.map((item: any, index: number) => {
                          return (
                            item.status !== crudStatus.DELETED &&
                            <Grid container key={index} spacing={{ md: 2, xs: 1 }} sx={{ marginBottom: '24px' }}>
                              <Grid item xs={10} sm={11}>
                                <TextField label="Address" variant="outlined" fullWidth
                                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                             handleChangeListItem('wallets', 'link', 'walletId')(event.target.value, index);
                                           }}
                                           error={hasError(item.walletId, index)}
                                           value={item.link}
                                />
                              </Grid>
                              <Grid item xs={2} sm={1} sx={{ display: 'flex' }}>
                                <div className="icon-button-wrapper">
                                  <IconButton onClick={() => handleDeleteListItem('wallets', 'walletId')(index)}>
                                    <ClearIcon/>
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          );
                        }
                      )
                    }
                    <Grid item xs={12}>
                      <Button startIcon={<AddIcon/>}
                              onClick={handleAddListItem('wallets', { walletId: createUUID(), link: '' })}
                              sx={{ textTransform: 'none', fontSize: '13px' }}
                      >Add wallet address</Button>
                    </Grid>
                  </>
                </Grid>
              }
            </Grid>
          </div>
          <div className="form__buttons">
            <div className="button-group">
              <Button variant="contained" onClick={handleSubmit}
                      sx={{ width: { md: 'auto' } }}
                      disabled={myDataLoading || loadingWalletsUpdate}
              >Save</Button>
            </div>
          </div>
        </div>
      </Panel>
    </Box>
  );
};
