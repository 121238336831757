import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Button, FormControl,
  Grid, InputLabel, MenuItem, Select,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { Modal, Panel } from '../../components/common';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import { Social } from '../../components/common/Social';
import {
  CREATE_CRYPTO_EVENT_SOURCE,
  DELETE_CRYPTO_EVENT_SOURCE,
  UPDATE_CRYPTO_EVENT_SOURCE
} from '../../../../store/mutations/cryptoEventSource';
import { GET_CRYPTO_EVENT_SOURCE_BY_ID } from '../../../../store/queries/cryptoEventSource';
import { SocialCodes } from "../../../../store/generated-models";

export const EventSourceDetailedView: FC<any> = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const validationConfig = {
    title: {
      custom: {
        isValid: (value: string) => {
          return value.length > 0;
        },
        message: 'The name cannot be empty'
      }
    },
    code: {
      type: 'required',
      message: 'Source type cannot be empty'
    },
    link: {
      custom: {
        isValid: (value: string) => {
          return value.length > 0;
        },
        message: 'The link cannot be empty'
      }
    }
  };

  type SourceForm = {
    title: string;
    code: string;
    link: string;
  }

  const initialValues: SourceForm = {
    title: '',
    code: '',
    link: ''
  };

  const {
    handleTextChange,
    handleSubmit,
    errors,
    data,
    setData,
    handleDateChange: _handleDateChange
  } = useForm<SourceForm>({ validationConfig, initialValues, onSubmit });

  const navigate = useNavigate();
  const [formLoaded, setFormLoaded] = useState(!id);

  const handleSave = () => {
    handleSubmit();
  };

  const { data: cryptoEventSourceData, loading } = useQuery(GET_CRYPTO_EVENT_SOURCE_BY_ID, {
    variables: {
      cryptoEventSourceId: id
    },
    fetchPolicy: 'network-only',
    skip: !id
  });

  useEffect(() => {
    let savedSourceData: typeof data = { ...data };

    if (cryptoEventSourceData) {
      let source = cryptoEventSourceData.getCryptoEventSourceById;

      for (const formField in savedSourceData) {
        // @ts-ignore
        savedSourceData[formField] = source[formField];
      }
      setData(savedSourceData);
      setFormLoaded(true);
    }
  }, [cryptoEventSourceData]);

  const [createSource, {
    data: dataCreate,
    loading: loadingSourceCreate,
    error: errorSourceCreate
  }] = useMutation(CREATE_CRYPTO_EVENT_SOURCE);

  const [updateSource, {
    data: dataUpdate,
    loading: loadingSourceUpdate,
    error: errorSourceUpdate
  }] = useMutation(UPDATE_CRYPTO_EVENT_SOURCE);

  const handleCancel = () => {
    navigate('/private/event-sources');
  };

  function onSubmit() {
    try {
      const cryptoEventSource: any = {
        title: data.title,
        code: data.code,
        link: data.link
      };
      if (id) {
        updateSource({
          variables: {
            cryptoEventSourceId: id,
            ...cryptoEventSource
          }
        }).then(() => {
          navigate('/private/event-sources');
        }).catch((error: any) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      } else {
        createSource({
          variables: {
            ...cryptoEventSource
          }
        }).then(() => {
          navigate('/private/event-sources');
        }).catch((error: any) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      }
    } catch (e) {
    }
  }

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);

  function confirmDelete() {
    setIsOpenDeleteDialog(true);
  }

  const [deleteSource, {
    data: dataDelete,
    loading: loadingSourceDelete,
    error: errorSourceDelete
  }] = useMutation(DELETE_CRYPTO_EVENT_SOURCE);
  const handleDelete = () => {
    deleteSource({
      variables: {
        cryptoEventSourceId: id
      }
    }).then(() => {
      navigate('/private/event-sources');
    }).catch((error: any) => {
      enqueueSnackbar('Internal server error', { ...{ variant: 'error' } });
    });
  };

  const sourceSocials = [
    {
      code: 'telegram',
      title: 'Telegram'
    },
    {
      code: 'twitter',
      title: 'Twitter'
    },
    {
      code: 'discord',
      title: 'Discord'
    }
  ];

  return (
    <Panel>
      <Modal
        open={isOpenDeleteDialog}
        title="Delete Source?"
        renderActions={() => <>
          <Button onClick={() => setIsOpenDeleteDialog(false)} disabled={loadingSourceDelete}>No</Button>
          <Button onClick={handleDelete} disabled={loadingSourceDelete}>Yes</Button>
        </>}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            {`Are you sure you want to delete the source "${data.title}"?`}
          </Typography>
        </>
      </Modal>

      <div className="form">
        <div className="form__body">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={{ xs: 2, sm: 4 }}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={errors.code?.length > 0}>
                    <InputLabel id="source-type">Source type</InputLabel>
                    <Select labelId="source-type"
                            label="Source type"
                            className="icon-select"
                            fullWidth
                            name="code"
                            value={data.code}
                            onChange={handleTextChange}
                    >
                      {
                        sourceSocials.filter(ss => ss.code == SocialCodes.Telegram).map((social, index) =>
                          <MenuItem key={index} value={social.code}>
                            <div key={index} style={{ display: 'flex' }}>
                              <Social socialCode={social.code}/>
                              <span>{social.title}</span>
                            </div>
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label="Source Name" name="title" variant="outlined"
                             fullWidth onChange={handleTextChange}
                             value={data.title} error={errors.title?.length > 0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Source Link" name="link" variant="outlined"
                             fullWidth onChange={handleTextChange}
                             value={data.link} error={errors.link?.length > 0}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="form__buttons">
          <div className="button-group">
            <Button variant="outlined" sx={{ width: { xs: id ? '96px' : '140px', md: 'auto' } }}
                    onClick={handleCancel}>Cancel</Button>
            {!isPhone && id && <Button sx={{ width: { xs: '96px', md: 'auto' } }} variant="outlined"
                                       onClick={confirmDelete} color="error"
                                       disabled={loadingSourceDelete}
            >Delete</Button>
            }
          </div>
          {isPhone && id && <div className="button-group">
            <Button variant="outlined" sx={{ width: { xs: '96px', md: 'auto' } }} onClick={confirmDelete}
                    color="error">Delete</Button>
          </div>}
          <div className="button-group">
            <Button variant="contained" sx={{ width: { xs: id ? '96px' : '140px', md: 'auto' } }}
                    disabled={(loadingSourceCreate && !errorSourceCreate) || (loadingSourceUpdate && !errorSourceUpdate)}
                    onClick={handleSave}>Save</Button>
          </div>
        </div>
      </div>
    </Panel>
  );
};
