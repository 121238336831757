import React, { FC, useContext, useEffect, useState } from 'react';
import { Badge, Button, Chip, Grid, Link, Skeleton, Typography } from '@mui/material';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  GET_ASSETS,
  GET_MY_ASSETS,
  GET_MY_WALLETS,
  USER_UPDATES_SUBSCRIPTION
} from '../../../../../store/queries/users';
import { Modal, Panel } from '..';
import DataTable, { COLUMN_TYPE } from '../DataTable/DataTable';
import { dateFormat, numberFormat, numberLimitFormat } from '../../../../../core/helpers/utils';
import { CryptoAsset } from '../../../../../store/generated-models';
import { ComplexAvatar } from '../ComplexAvatar';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { FOLLOW_CRYPTO_ASSET, UNFOLLOW_CRYPTO_ASSET } from '../../../../../store/mutations/subscription';
import { useSnackbar } from 'notistack';
import { GraphQLError } from 'graphql';
import { getIcon } from '../CustomIcon';

export const CryptoAssets: FC<any> = (props) => {
  const { navigateToWallets, isAdminPage } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [wallets, setWallets] = useState([]);

  const { data: walletsData, loading: walletsDataLoading, refetch: refetchWalletsData } = useQuery(GET_MY_WALLETS, {
    fetchPolicy: 'network-only',
    skip: isAdminPage
  });

  useEffect(() => {
    if (walletsData) {
      setWallets(walletsData.me.wallets);
    }
  }, [walletsData]);

  const [assets, setAssets] = useState([]);

  const { data: assetsData, loading: loadingAssets, refetch: refetchAssets } = useQuery(GET_ASSETS, {
    fetchPolicy: 'network-only',
    skip: !isAdminPage
  });

  useEffect(() => {
    if (assetsData) {
      setAssets(assetsData.getCryptoAssets);
    }
  }, [assetsData]);

  const { data: myAssetsData, loading: loadingMyAssets, refetch: refetchMyAssets } = useQuery(GET_MY_ASSETS, {
    fetchPolicy: 'network-only',
    skip: isAdminPage
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (myAssetsData) {
      setAssets(myAssetsData.myAssets);
    }
  }, [myAssetsData]);

  const [followCryptoAsset, {
    data: dataUnfollow,
    loading: loadingUnfollow
  }] = useMutation(FOLLOW_CRYPTO_ASSET);

  const [unfollowCryptoAsset, {
    data: dataFollow,
    loading: loadingFollow
  }] = useMutation(UNFOLLOW_CRYPTO_ASSET);

  /* Crypto assets held in user wallets as well as their metadata are constantly changing. Also the first extraction
   * of the crypto assets held can take considerable time along with non-cached assets metadata update (external API
   * integration is used for this). Web-sockets are used to communicate the updates.
   *
   * Corresponding topic events are filtered by custom ID (kind of events scoping): subscriptionEventIds.
   * The unique "scope" for the user wallets and assets held in them is user object itself. We shrink such a broad
   * scope by my-assets- prefix for the IDs to avoid data re-fetch on every potential user-related updates.
   */
  const authContext = useContext(AuthContext);
  const userId = authContext.user.userId;
  const { data: userUpdatesSubscriptionData, loading: userUpdatesSubscriptionLoading } = useSubscription(
    USER_UPDATES_SUBSCRIPTION, { variables: { subscriptionEventIds: ['my-assets-' + userId] } }
  );

  useEffect(() => {
    refetchMyAssets().then();
  }, [userUpdatesSubscriptionData?.myAssetsUpdate]);

  const infoPanelsData = [
    {
      icon: getIcon('wallet'),
      title: 'Get notifications for new airdrops',
      items: [
        'Billions of dollars are released every year in crypto-airdrops!',
        'We monitor your addresses and notify you if there is a match!'
      ]
    },
    {
      icon: getIcon('email'),
      title: 'Personalized Email Notifications',
      items: [
        'Tailor your notifications! Choose the crypto assets in your wallet you want to monitor, and we\'ll send you personalized updates on events that matter to you.'
      ]
    },
    {
      icon: getIcon('alert'),
      title: 'Comprehensive Event Tracking',
      items: [
        'Stay alert to the full spectrum of crypto activities, including hard forks, rug pulls, and bridging events, to act swiftly and securely.'
      ]
    }
  ];

  const InfoPanels = <Grid container spacing={{ xs: 3 }} marginBottom="24px">
    {
      infoPanelsData.map((panel, index) => <Grid item key={index} xs={12} md={4}>
        <Panel fullHeight>
          <div className="info-panel">
            <div className="info-panel__header">
              <div className="info-panel__icon">
                {panel.icon}
              </div>
              <div className="info-panel__title">
                <Typography fontSize='20px' lineHeight='22px' fontWeight={500}>{panel.title}</Typography>
              </div>
            </div>
            <div className="info-panel__content">
              {
                panel.items.map((item, index) => <div key={index} className="info-panel__item">
                    <Typography>{item}</Typography>
                  </div>
                )
              }
            </div>
          </div>
        </Panel>
      </Grid>)
    }
  </Grid>;

  const Notifier = <>
    <Grid container spacing={{ xs: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h6">Crypto notifier</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography marginBottom="16px">
          Ensure you're always ahead of the game in the crypto world. Our Crypto Event Notifier service enables you to manage your
          portfolio proactively by providing timely notifications about the events that could impact your digital assets.
          Add your wallet, select your preferences, and never miss an event.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={navigateToWallets} sx={{ width: { md: 'auto' } }}>Add Wallet</Button>
      </Grid>
    </Grid>
  </>;

  const [subscriptionInProcess, setSubscriptionInProcess] = useState<any>([]);
  const isInProcess = (assetId: any) => {
    return subscriptionInProcess.includes(assetId);
  };

  let columns: any[] = [
    {
      code: 'symbol',
      label: 'Asset symbol',
      render: (row: any, column: any) => {
        const symbol = row['symbol'],
          logoUrl = row['logoUrl'],
          link = logoUrl ? `${logoUrl}` : '';

        return <ComplexAvatar width={32} height={32} src={link} label={symbol}/>;
      },
      loadingRender: () => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 0, marginRight: '16px' }}>
            <Skeleton variant="circular" width={32} height={32}/>
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton variant="text"/>
          </div>
        </div>;
      }
    },
    {
      code: 'title',
      label: 'Asset Name',
      render: (row: any, column: any) => {
        return row['title'].slice(0, 30);
      }
    }
  ];

  if (!isAdminPage) {
    columns.push({
      code: 'numberOfNewEvents',
      label: 'New events',
      align: 'center',
      contentAlign: 'center',
      render: (row: any, column: any) => {
        const value = row['statistics'][column.code];
        return value && value > 0
          ? <Badge badgeContent={numberLimitFormat(value, 99, '99+')} color="primary"/>
          : '';
      }
    });
  }

  if (isAdminPage) {
    columns = [
      ...columns,
      {
        code: 'numberOfEvents',
        label: 'Events',
        align: 'center',
        contentAlign: 'center',
        render: (row: any, column: any) => {
          return `${numberFormat(row['statistics'][column.code] || 0)}`;
        }
      },
      {
        code: 'numberOfFollowers',
        label: 'Followers',
        align: 'center',
        contentAlign: 'center',
        render: (row: any, column: any) => {
          return `${numberFormat(row['statistics'][column.code] || 0)}`;
        }
      },
      {
        code: 'lastEventDate',
        label: 'Last event',
        width: '150px',
        type: COLUMN_TYPE.DATE,
        render: (row: any, column: any) => {
          return dateFormat(row['statistics'][column.code]);
        }
      }
    ];
  }
  columns.push(
    {
      code: 'isScam',
      label: 'Scam indicator',
      render: (row: any, column: any) => {
        const isScam = row[column.code];

        return <Chip label={isScam ? 'Scam' : 'Not Scam'}
                     sx={{
                       color: isScam ? '#E2507A' : '#2BB596', width: '100px',
                       backgroundColor: isScam ? '#FAE2E9' : '#D6F5EE'
                     }}
        />;
      }
    }
  );
  if (!isAdminPage) {
    columns.push(
      {
        code: 'isSubscribed',
        label: '',
        width: '150px',
        isClickDisabled: true,
        render: (row: any, column: any) => {
          const isSubscribed = row['userData'][column.code];

          const buttonData: any = {
            disabled: isInProcess(row.cryptoAssetId),
            label: isSubscribed ? 'Unsubscribe' : 'Subscribe',
            color: isSubscribed ? 'error' : 'primary',
            handler: (assetId: string) => toggleSubscribe(assetId, isSubscribed)
          };

          return <Button color={buttonData.color} disabled={buttonData.disabled}
                         onClick={(event) => {
                           event.stopPropagation();
                           buttonData.handler(row.cryptoAssetId);
                         }}
          >{buttonData.label}</Button>;
        }
      }
    );
  }

  const rowOnClick = (row: CryptoAsset) => {
    const id = row.cryptoAssetId;

    if (id) {
      if (isAdminPage) {
        navigate(`/private/crypto-asset-edit/${id}`);
      } else {
        navigate(`/private/crypto-asset-detailed/${id}`);
      }
    }
  };

  const testAssetsData = {
    myAssets: [
      {
        cryptoAssetId: '4354jghfgn653g345t',
        symbol: 'BTC',
        title: 'Bitcoin',
        numberOfNewEvents: 1,
        contractAddress: '34xp4vRoCGJym3xR7yCVPFHoCNxv4Twseo',
        status: 'Active',
        isScam: true,
        isSubscribed: false
      }
    ]
  };

  const navigateToSubscription = () => {
    setIsOpenTierConstraintsModal(false);
    navigate('/private/subscription');
  };
  const subscriptionsLink = <Link onClick={navigateToSubscription}>here</Link>;
  const [isOpenTierConstraintsModal, setIsOpenTierConstraintsModal] = useState(false);
  const tierConstraintsModal = () => <>
    <Modal
      open={isOpenTierConstraintsModal}
      title="Upgrade your plan"
      renderActions={() => <>
        <Button onClick={() => setIsOpenTierConstraintsModal(false)}>Ok</Button>
      </>}
    >
      <>
        <Typography>
          You can watch up to 5 coins in your subscription level.
        </Typography>
        <Typography>
          If you want to watch more, please upgrade to the premium plan {subscriptionsLink}
        </Typography>
      </>
    </Modal>
  </>;

  const toggleSubscribe = (assetId: string, unsubscribe: boolean = false) => {
    setSubscriptionInProcess([...subscriptionInProcess, assetId]);
    const options = { variables: { cryptoCryptoAssetId: assetId } };
    const mutation = unsubscribe ? unfollowCryptoAsset : followCryptoAsset;
    mutation(options).then(() => {
      setSubscriptionInProcess((prevState: any) => {
        return prevState.filter((id: any) => id != assetId);
      });
      setAssets((prevState: any[]) => {
        return prevState.map(item => ({
            ...item,
            userData: {
              isSubscribed: item.cryptoAssetId === assetId ? !unsubscribe : item.userData?.isSubscribed
            }
          })
        );
      });
      enqueueSnackbar(unsubscribe ? 'Unsubscribed' : 'Subscribed', { variant: 'success' });
    }).catch((error: any) => {
      setSubscriptionInProcess((prevState: any) => {
        return prevState.filter((id: any) => id != assetId);
      });
      enqueueSnackbar(error.message, { variant: 'error' });
      const isLimitErr = error.graphQLErrors?.filter((err: GraphQLError) => {
        return err.extensions?.code === 'billing.subscription.limit_reached';
      }).length > 0;
      setIsOpenTierConstraintsModal(isLimitErr);
    });

    return true;
  };

  const loading = loadingAssets || loadingMyAssets || walletsDataLoading;

  const Assets = <>
    {tierConstraintsModal()}
    {
      <>
        <Grid item>
          <DataTable rows={assets}
                     columns={columns} isEditOnClick
                     rowOnClick={rowOnClick}
                     isWithPagination
                     isLoading={loading}
          />
        </Grid>
      </>
    }
  </>;

  return (
    <Grid container spacing={{ md: 4, xs: 2 }} style={{ maxWidth: '1280px' }}>
      <Grid item xs={12}>
        {(isAdminPage || loading || wallets.length > 0) ? '' : InfoPanels}
        <Panel>
          {(isAdminPage || loading || wallets.length > 0) ? Assets : Notifier}
        </Panel>
      </Grid>
    </Grid>
  );
};
