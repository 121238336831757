import React from 'react';
import { concatClassNames, dateFormat, numberFormat } from 'core/helpers/utils';
import './CryptoEvent.scss';
import { getIcon } from '../CustomIcon';

export const CryptoEvent = (props: any) => {
  const { data } = props;
  const classNames = concatClassNames({});

  return (
    <div className={`transaction ${classNames}`}>
      <div className="transaction__content">
        <div className="transaction__header">
          <div className="transaction__icon">
            {data.isFake && getIcon('error')}
            {!data.isFake && getIcon('checkboxChecked')}
          </div>
          <div className="transaction__title">
            {data.title}
          </div>
        </div>
        <div className="transaction__data">
          <div className="transaction__date">
            {dateFormat(data.date)}, {data.socialTitle}
          </div>
          {
            data.description && <div className="transaction__comment">
              <div dangerouslySetInnerHTML={{ __html: data.description }}/>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
