import { gql } from '@apollo/client';

export const GET_PARTICIPATION_REQUESTS = gql`
  query GetParticipationRequests($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getProjectPRequests(filterEx: $filterEx, orderBy: $orderBy) {
      list {
        projectPRequestId
        fundName
        contribution
        created
        status
        investmentAmount
        investmentApprovedAmount
        investmentCurrency
        decided
        isLegalPerson
        user {
          fullName
        }
        contract {
          contractId
          signedByBuyer
          signedBySeller
          finalCopyUrl
          created
        }
        novations {
          novationId
          signedByBuyer
          signedBySeller
          finalCopyUrl
          created
        }
        project {
          title
        }
      }
    }
  }
`;

export const GET_FUND_PARTICIPATION_REQUESTS = gql`
  query GetRollingFundPRequests($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getRollingFundPRequests(filterEx: $filterEx, orderBy: $orderBy) {
      list {
        rollingFundPRequestId
        investmentCurrency
        investmentAmount
        period
        periodType
        isLegalPerson
        sourceOfFunds
        sourceOfFundsOther
        occupation
        income
        assets
        motivation
        rollingFund {
          title
        }
        user {
          fullName
        }
        contract {
          contractId
          signedByBuyer
          signedBySeller
          finalCopyUrl
          created
        }
        status
        decided
        created
        updated
      }
    }
  }
`;

export const GET_PARTICIPATION_REQUEST_BY_ID = gql`
  query GetParticipationRequestById($participationRequestId: String) {
    getProjectPRequestById(participationRequestId: $participationRequestId) {
      projectPRequestId
      fundName
      contribution
      leadSource
      investmentCurrency
      investmentApprovedAmount
      investmentAmount
      status
      decided
      isLegalPerson
      userSocials {
        socialDataId
        profileName
        profileUrl
        href
        social {
          socialCode
          title
        }
      }
      communitySocials {
        socialDataId
        profileName
        profileUrl
        href
        social {
          socialCode
          title
        }
      }
      project {
        projectId
        title
        contributionWallet
        contributionWalletShort
        contributionWalletUrl
        externalTemplateId
        rounds {
          projectRoundId
          title
          price
          tokenCount
        }
      }
      user {
        userId
        fullName
      }
      contract {
        contractId
        buyerId
        sellerId
        signedByBuyer
        signedBySeller
        isComplete
        fundsReceived
        finalCopyUrl
        created

        agreementDate
        buyerDesignation1
        paymentModeUsdc
        paymentModeUsdt
        transactionId
        transactionIdShort
        transactionUrl
        buyerWalletAddress
        buyerWalletShort
        buyerWalletUrl
        buyerSourceOfFunds
        buyerDesignation2
        buyerFraudReported
        buyerPep
        buyerAgreeTerms
      }
      novations {
        novationId
        buyerId
        sellerId
        signedByBuyer
        signedBySeller
        isComplete
        finalCopyUrl
        created
      }
      tokenAllocation {
        tokenAllocationId
        projectRound {
          projectRoundId
          title
          price
        }
        tokenCount
        totalPrice
      }
      created
      updated
      userActionLog {
        userAction {
          userActionId
          userId
          objectId
          actionType
          linkedIds
          info
          result
          status
          date
        }
        tokenAllocation {
          tokenAllocationId
          projectRound {
            projectRoundId
            title
            tokenCount
            price
          }
          tokenCount
          totalPrice
        }
        comment
      }
      progress {
        isComplete
        steps {
          code
          isComplete
          isError
        }
      }
    }
  }
`;

export const GET_FUND_PARTICIPATION_REQUEST_BY_ID = gql`
  query GetRollingFundPRequestById($participationRequestId: String) {
    getRollingFundPRequestById(participationRequestId: $participationRequestId) {
      rollingFundPRequestId
      investmentCurrency
      investmentAmount
      period
      periodType
      isLegalPerson
      sourceOfFunds
      sourceOfFundsOther
      occupation
      income
      assets
      motivation
      status
      rollingFund {
        rollingFundId
        title
        startDate
        managementFee
        contributionWallet
        contributionWalletShort
        contributionWalletUrl
        externalTemplateId
      }
      user {
        userId
        fullName
      }
      contract {
        contractId
        buyerId
        sellerId
        signedByBuyer
        signedBySeller
        isComplete
        fundsReceived
        finalCopyUrl
        created

        agreementDate
        buyerDesignation1
        paymentModeUsdc
        paymentModeUsdt
        transactionId
        transactionIdShort
        transactionUrl
        buyerWalletAddress
        buyerWalletShort
        buyerWalletUrl
        buyerSourceOfFunds
        buyerDesignation2
        buyerFraudReported
        buyerPep
        buyerAgreeTerms
      }
      userActionLog {
        userAction {
          userActionId
          userId
          objectId
          actionType
          linkedIds
          info
          result
          status
          date
        }
        tokenAllocation {
          tokenAllocationId
          projectRound {
            projectRoundId
            title
            tokenCount
            price
          }
          tokenCount
          totalPrice
        }
        comment
      }
      progress {
        isComplete
        steps {
          code
          isComplete
          isError
        }
      }
      created
      updated
    }
  }
`;

export const GET_DOCUMENT_SIGN_URL = gql`
  query GetDocumentSignUrl($documentId: String) {
    getDocumentSignUrl(documentId: $documentId)
  }
`;

export const GET_DOCUMENT_FINAL_COPY_URL = gql`
  query GetDocumentFinalCopyUrl($documentId: String) {
    getDocumentFinalCopyUrl(documentId: $documentId)
  }
`;

export const PARTICIPATION_REQUEST_SUBSCRIPTION = gql`
  subscription DocumentUpdates($subscriptionEventIds: [String]) {
    documentSigned(subscriptionEventIds: $subscriptionEventIds)
  }
`;
