import React, { Context, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  User,
  LoginResult,
  UserRole, UserMode
} from '../../store/generated-models';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { tokenStorage } from '../../bundles/common/tokenStorage';
import config from '../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import { PopupMessage } from '../components/PopupMessage';
import { useApolloClient, useMutation, useQuery, gql, useSubscription } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { getIcon } from '../../bundles/private/components/common/CustomIcon';
import {
  GET_ME,
  GET_MENU_ITEMS,
  GET_MY_ASSETS,
  USER_NOTIFICATIONS_SUBSCRIPTION,
  USER_UPDATES_SUBSCRIPTION
} from "../../store/queries/users";
import { v4 as uuidv4 } from 'uuid';
import { numberLimitFormat } from '../helpers/utils';

const SESSION_INACTIVITY_TIMEOUT = 1800; // seconds before auto-logout kicks in
const SESSION_TIMEOUT_COUNTDOWN = 30; // seconds to show the dialog before logging out

const clearUser: User = {
  userId: '',
  email: '',
  systemUser: false,
  roles: [],
  is2faEnabled: false,
  hasEmailAuth: false
};

type IconsMapping = {
  users: any
  // projects: any,
  // requests: any,
  // investor_requests: any,
  my_account: any,
  sales: any,
  fund_investors: any,
  faq: any,
  contact_support: any,
  rolling_funds: any,
  crypto_notifier: any
}

const iconsMapping: IconsMapping = {
  users: getIcon('users'),
  // projects: getIcon('projects'),
  // requests: getIcon('requests'),
  // investor_requests: getIcon('requests'),
  fund_investors: getIcon('investors'),
  my_account: getIcon('user'),
  sales: getIcon('sales'),
  faq: getIcon('question'),
  contact_support: getIcon('mail'),
  rolling_funds: getIcon('investment_portfolio'),
  crypto_notifier: getIcon('crypto_notifier')
};

export interface AuthContextProps {
  isInitialized: boolean;
  user: User | null;
  getSettings: () => void,
  login: (loginResult: LoginResult) => void
  logout: () => void,
  clearSession: () => void,
  recaptchaToken: string | null;
  updateRecaptcha: () => Promise<string> | null;
  showMessage: (type: 'success' | 'error', message: string) => void;
  isNewNotification: boolean;
  setIsNewNotification: (flag: boolean) => void;
  notificationCounter: number;
  incNotificationCounter: () => void;
  updateUser: (param: any) => void;
  menuItems: any[];
  isUser: boolean,
  isAdmin: boolean,
  isFundOwner: boolean,
  isContractSigner: boolean,
  isVerified: boolean,
  blockpassWidget: any,
  setBlockpassWidget: any
}

export const AuthContext: Context<AuthContextProps> = React.createContext(null);

export const AuthProvider: React.FC = ({ children }) => {

  const gqlClient = useApolloClient();

  const {
    error: userError,
    data: userData,
    refetch: userRefetch
  } = useQuery<{ me: User }>(GET_ME);

  const getSettings = () => {
    // settingsRefetch().then();
  };

  const [logoutMutation, {
    error: logoutMutationError,
    data: logoutMutationData
  }] = useMutation<{ logout: boolean }>(gql`
    mutation Logout {
      logout
    }
  `);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);
  const [notificationCounter, setNotificationCounter] = useState(0);

  const [user, setUser] = useState<User>(clearUser);
  const [settings, setSettings] = useState<any>({});
  const [transactionParams, setTransactionParams] = useState<any>({});

  const userRef = useRef<User>(null); // need that for using in click event listeners and timeouts, otherwise they won't access the current value
  const companyRef = useRef(null);
  const settingsRef = useRef(null);
  const transactionParamsRef = useRef(null);

  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();

  const userId = user?.userId;
  const { data: menuItemsData, loading: loadingMenuItems, refetch: refetchMenuItems } = useQuery(GET_MENU_ITEMS, {
    fetchPolicy: 'network-only',
    skip: !userId
  });
  
  const { data: notificationsData } = useSubscription(
    USER_NOTIFICATIONS_SUBSCRIPTION, {
      variables: { subscriptionEventIds: ['my-notifications-' + userId] },
      skip: !userId
    }
  );
  
  useEffect(() => {
    if (menuItemsData) {
      setMenuItems(menuItemsData.menuItems);
      constructMenuItems(menuItemsData.menuItems);
    }
  }, [menuItemsData]);
  
  useEffect(() => {
    refetchMenuItems();
  }, [notificationsData?.newNotification]);

  const constructMenuItems = (menuItems: any[] = []) => {
    const menuItemsMapping: any = {
      'sales': ['projects', 'requests', 'investor_requests'],
      'users': ['investors', 'fund_investors', 'fund_managers'],
      'my_account': ['verification', 'profile', 'wallets', 'contracts', 'subscription'],
      'crypto_notifier': ['crypto_assets', 'event_sources'],
      'rolling_funds': ['funds', 'fund_requests', 'disclaimer']
    };
    const menuLocationsMapping: any = {
      'projects': ['/private/projects', '/private/project-detailed', '/private/participation-request'],
      'investor_requests': ['/private/investor-requests', '/private/request-detailed'],
      'funds': ['/private/funds', '/private/fund-detailed'],
      'fund_requests': ['/private/fund-requests', '/private/fund-request-detailed'],
      'requests': ['/private/requests', '/private/request-detailed'],
      'investors': ['/private/investors', '/private/investor-detailed'],
      'fund_managers': ['/private/fund-managers'],
      'profile': ['/private/profile'],
      'wallets': ['/private/wallets', '/private/crypto-asset-detailed'],
      'crypto_assets': ['/private/crypto-assets', '/private/crypto-asset-edit'],
      'event_sources': ['/private/event-sources', '/private/event-source-detailed'],
      'subscription': ['/private/subscription']
    };
    let userMenuItems: any[] = [];

    menuItems.forEach((menuItem: any) => {
      const newItemMenu = {
        ...menuItem,
        link: menuItem.description,
        code: menuItem.code,
        label: menuItem.name,
        badge: numberLimitFormat(menuItem.badgeValue, 99, '99+'),
        icon: iconsMapping[menuItem.code as keyof IconsMapping],
        userValid: false,
        menuItems: [],
        allowedPaths: menuLocationsMapping[menuItem.code],
        isBottom: ['contact_support', 'faq'].includes(menuItem.code)
      };

      let parentMenuItem = userMenuItems.find(item => menuItemsMapping[item.code] && menuItemsMapping[item.code].includes(menuItem.code));

      if (parentMenuItem) {
        parentMenuItem.menuItems.push(newItemMenu);
      } else {
        userMenuItems.push(newItemMenu);
      }
    });

    setMenuItems(userMenuItems);
  };

  const login = useCallback((loginResult: LoginResult) => {
    setUser(loginResult.user);
    tokenStorage.setAccessToken(loginResult.authToken);
    userRef.current = user;
    settingsRef.current = settings;
    transactionParamsRef.current = transactionParams;
    refetchMenuItems();
    setIsInitialized(true);

    navigate('/private');

  }, []);

  const logout = useCallback(() => {
    logoutMutation().catch().then(() => {
      navigate('/login');
    });
  }, []);

  const clearSession = () => {
    tokenStorage.clearToken();
    setUser(clearUser);
    userRef.current = null;
    companyRef.current = null;
    settingsRef.current = null;
    transactionParamsRef.current = null;
    resetWatchdog();
    gqlClient.clearStore().catch();
  };

  // Logout effect
  useEffect(() => {
    if (logoutMutationData) {
      // console.debug('>> logoutMutationData');
      clearSession();
    }
  }, [logoutMutationError, logoutMutationData]);

  // Initialize auth state
  useEffect(() => {
    // try to use refresh token. Manually call the mutation to use cookies (the main graphql client doesn't pass cookies)
    const params = {
      operationName: 'RefreshToken',
      query: `
            mutation RefreshToken {
              refreshToken
            }
          `
    };

    // Execute the re-authorization request and set the promise returned to this.refreshPromise
    fetch(config.apiUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(params)
    })
      .then(refreshResponse => refreshResponse.json())
      .then(refreshResponseJson => {
        if (refreshResponseJson.data && refreshResponseJson.data.refreshToken) {
          tokenStorage.setAccessToken(refreshResponseJson.data.refreshToken as string);
          userRefetch().then();
        } else {
          setIsInitialized(true);
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }, []); // eslint-disable-line

  // region Recaptcha helpers
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef: MutableRefObject<ReCAPTCHA> = useRef(null);
  const recaptchaPromiseCallbacks: MutableRefObject<{
    accept: (value?: string | PromiseLike<string>) => void,
    reject: (reason?: string) => void
  }> = useRef(null);

  const updateRecaptcha = useCallback(() => {
    if (recaptchaRef.current) {
      return new Promise<string>((accept, reject) => {
        recaptchaPromiseCallbacks.current = {
          accept: accept,
          reject: reject
        };
        recaptchaRef.current.reset();
        recaptchaRef.current.execute();
      });

    }
    return null;
  }, [recaptchaRef, recaptchaPromiseCallbacks]);

  const onRecaptchaChange = useCallback((token: any) => {
    if (token) {
      recaptchaPromiseCallbacks.current.accept(token);
    } else {
      recaptchaPromiseCallbacks.current.reject();
    }
    setRecaptchaToken(token);
  }, []);

  // region error message helpers
  const [messageState, setMessageState] = useState({ type: null, message: null });

  const showMessage = useCallback((type, message) => {
    setMessageState({ type: type, message: message });

    setTimeout(() => {
      setMessageState({ type: null, message: null });
    }, 6000);
  }, []);

  // Current user request effect
  useEffect(() => {
    if (userData) {
      setUser(userData.me);
      userRef.current = userData.me;
      resetWatchdog();
      setIsInitialized(true);
      return;
    }
  }, [userData]);

  useEffect(() => {
    if (userError) {
      setIsInitialized(true);
      return;
    }
  }, [userError]);

  // region timeout dialog
  const [isTimeoutDialogOpen, setTimeoutDialogOpen] = useState<boolean>(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState<number>(SESSION_TIMEOUT_COUNTDOWN);
  const watchdogTimeout = useRef<any>(null);

  const resetWatchdog = () => {
    if (watchdogTimeout.current) {
      clearTimeout(watchdogTimeout.current);
    }

    // activate watchdog if user is logged in
    if (userRef.current) {
      watchdogTimeout.current = setTimeout(() => {
        showTimeoutDialog();
      }, SESSION_INACTIVITY_TIMEOUT * 1000);
    }
  };

  const handleTimeoutDialogClose = () => {
    setTimeoutDialogOpen(false);
  };

  const showTimeoutDialog = () => {
    setTimeoutCountdown(SESSION_TIMEOUT_COUNTDOWN);
    setTimeoutDialogOpen(true);
  };

  const updateNotificationCounter = () => {
    setNotificationCounter(notificationCounter + 1);
  };

  // countdown effect
  useEffect(() => {
    let timeout: any = null;
    if (isTimeoutDialogOpen) {
      // time is up
      if (timeoutCountdown <= 0) {
        handleTimeoutDialogClose();
        logout();
      }
      timeout = setTimeout(() => {
        setTimeoutCountdown(timeoutCountdown => timeoutCountdown - 1);
      }, 1000);
    } else {
      if (!isTimeoutDialogOpen) {
        clearTimeout(timeout);
      }
    }
    return () => clearInterval(timeout);
  }, [isTimeoutDialogOpen, timeoutCountdown]); // eslint-disable-line

  // start activity tracking watchdog
  useEffect(() => {
    resetWatchdog();

    document.body.addEventListener('click', resetWatchdog);

    return () => {
      if (watchdogTimeout.current) {
        document.body.removeEventListener('click', resetWatchdog);
        clearTimeout(watchdogTimeout.current);
      }
    };
  }, []);

  const updateUser = (params: any) => {
    setUser({ ...user, ...params });
  };

  const [blockpassWidget, setBlockpassWidget] = useState(null);
  const kycType = config.bpServiceId;
  const [refId, setRefId] = useState('');
  const [kycValid, setKycValid] = useState(false);
  const [kycStatus, setKycStatus] = useState('...');

  useEffect(() => {
    if (user?.userId && user.userId !== '') {
      const refIdValue = `${user.userId}-1eb1d3308cd1eb1d3303af-${uuidv4()}`;
      setRefId(user.email);
      setKycValid(!!user.kycValid);
      setKycStatus(user.kycStatus);
    }
  }, [user]);


  useEffect(() => {
    if (!kycType) return;
    if (refId) {
      loadBlockpassWidget();
    }
  }, [refId]);

  // SDK Widget tried to cache iframe data after first load
  //.  - In case only 1 client id => it work fine.
  //.  - If we trying to use 2 or more clientIds on same page => got issue
  // ---------
  // Temporary walkaround to force cleanup cache in Widget V3
  const cleanUp = () => {
    const oldNode = document.getElementById('blockpass-kyc-connect');
    const newNode = oldNode.cloneNode(true);
    oldNode.parentNode.replaceChild(newNode, oldNode);
  };

  function loadBlockpassWidget() {
    // @ts-ignore
    const blockpass = new window.BlockpassKYCConnect(
      kycType, // service client_id from the admin console
      { refId: refId } // assign the local user_id of the connected user
    );
    blockpass.startKYCConnect();
    setBlockpassWidget(blockpass);
  }

  return (
    <AuthContext.Provider value={{
      isInitialized: isInitialized,
      user: user,
      getSettings: getSettings,
      login: login,
      logout: logout,
      clearSession: clearSession,
      recaptchaToken: recaptchaToken,
      updateRecaptcha: updateRecaptcha,
      showMessage: showMessage,
      isNewNotification: isNewNotification,
      setIsNewNotification: setIsNewNotification,
      notificationCounter: notificationCounter,
      incNotificationCounter: updateNotificationCounter,
      updateUser: updateUser,
      menuItems: menuItems,
      isContractSigner: user.roles.findIndex((role: UserRole) => role.code === 'CONTRACT_SIGNER') !== -1,
      isAdmin: user.roles.findIndex((role: UserRole) => role.code === 'ADMIN') !== -1,
      isUser: user.roles.findIndex((role: UserRole) => role.code === 'USER') !== -1,
      isFundOwner: user.roles.findIndex((role: UserRole) => role.code === 'FUND_OWNER') !== -1,
      isVerified: user.mode === UserMode.VerifiedUser && user.kycStatus === 'approved',
      blockpassWidget,
      setBlockpassWidget

    }}>
      {children}
      <PopupMessage type={messageState.type} message={messageState.message}/>
      <Dialog
        open={isTimeoutDialogOpen}
        onClose={handleTimeoutDialogClose}
        aria-labelledby="session-timeout-dialog-title"
        aria-describedby="session-timeout-description"
      >
        <DialogTitle id="session-timeout-dialog-title">You will be logged out
          in {timeoutCountdown} second{timeoutCountdown > 1 ? 's' : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="session-timeout-dialog-description">
            You seem to be inactive for a while. For your security, you will be logged out from the current session.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTimeoutDialogClose} variant="contained" color="primary" autoFocus>
            I'm still here
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'none' }} id="blockpass-kyc-connect"/>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={config.recaptchaSiteKey}
        size="invisible"
        onChange={onRecaptchaChange}
      />
    </AuthContext.Provider>
  );
};
