import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography, IconButton, InputAdornment, InputLabel, Select, MenuItem, FormControl, Avatar, Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useDropzone } from 'react-dropzone';
import { useMutation, useQuery } from '@apollo/client';
import { DatePicker } from '@mui/lab';
import { CREATE_PROJECT } from '../../../../store/mutations/';
import './ProjectDetailedView.scss';
import { getIcon } from '../../components/common/CustomIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_PROJECT_BY_ID } from '../../../../store/queries/projects';
import { DELETE_PROJECT, UPDATE_PROJECT } from '../../../../store/mutations/project';
import { GET_HS_TEMPLATES } from '../../../../store/queries/helloSign';
import SocialList from '../../components/common/SocialList';
import { crudStatus } from '../../../../core/constants/common';
import { normalizeRounds, normalizeSocials } from '../../helpers/Normalize';
import { HsTemplateType, ProjectInput, ProjectStatusEnum } from '../../../../store/generated-models';
import { useForm } from '../../hooks/useForm';
import { Modal, Panel } from '../../components/common';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import { PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { CompletedSpacing, CropImageModal, OriginalData } from './CropImageModal';
import { canvasPreview } from './canvasPreview';
import { NumberInput } from '../../components/common/NumberInput';
import { ExpandMore } from '../../components/common/ExpandMore/ExpandMore';

export const ProjectDetailedView: FC<any> = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const validationConfig = {
    title: {
      custom: {
        isValid: (value: string) => {
          return value.length > 0;
        },
        message: 'The name cannot be empty'
      }
    },
    socials: {
      type: 'social'
    },
    rounds: {
      type: 'rounds'
    }
  };

  type ProjectForm = {
    title: string;
    tokenName: string;
    tokenSymbol: string;
    whitepaperLink: string;
    description: string;
    externalTemplateId: string;
    contributionWallet: string;
    status: boolean;
    tokenGenerationDate: Date | null;
    socials: any[];
    rounds: any[];
  }

  const initialValues: ProjectForm = {
    title: '',
    tokenName: '',
    tokenSymbol: '',
    whitepaperLink: '',
    description: '',
    externalTemplateId: '',
    contributionWallet: '',
    status: true,
    tokenGenerationDate: null,
    socials: [],
    rounds: []
  };

  const {
    handleTextChange,
    handleSubmit,
    errors,
    data,
    setData,
    handleCheckboxChange,
    handleDateChange: _handleDateChange,
    handleAddSocial,
    handleSocialChange,
    handleSocialTitleChange,
    handleDeleteSocial,
    handleAddRound,
    handleRoundChange,
    handleDeleteRound
  } = useForm<ProjectForm>({ validationConfig, initialValues, onSubmit });
  const onRoundChange = handleRoundChange('rounds');
  const onRoundDelete = handleDeleteRound('rounds');
  const roundErrors = errors.rounds;

  const navigate = useNavigate();
  const [formLoaded, setFormLoaded] = useState(!id);

  const [withdrawExpanded, setWithdrawExpanded] = useState(false);
  const [withdrawApproval, setWithdrawApproval] = useState(false);
  const [novationExpanded, setNovationExpanded] = useState(false);
  const [moveToNovation, setMoveToNovation] = useState(false);

  const approvedRequestCount = 3;
  const countersignedCount = 2;
  const [isOpenTokenomicsDialog, setIsOpenTokenomicsDialog] = useState(false);
  const tokenomicsModal = () => <Modal
    open={isOpenTokenomicsDialog}
    title="Tokenomics Change"
    renderActions={() => <>
      <Button onClick={() => setIsOpenTokenomicsDialog(false)}>Cancel</Button>
      <Button onClick={handleSubmit}>Save</Button>
    </>}
  >
    <>
      <Typography sx={{ marginBottom: '16px' }}>
        {
          `You are about to change the Tokenomics on this project. 
          You have ${approvedRequestCount} already approved requests and ${countersignedCount} countersigned contracts.`
        }
      </Typography>
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <div style={{ display: 'flex' }}>
          <Switch name="withdrawApproval" checked={withdrawApproval}
                  onChange={(event) => setWithdrawApproval(event.target.checked)}
          />
          <div>
            <Typography>
              Withdraw approval from all already approved requests
            </Typography>
            <ExpandMore
              expand={withdrawExpanded}
              onClick={() => setWithdrawExpanded(!withdrawExpanded)}
              aria-expanded={withdrawExpanded}
              aria-label="Learn more"
            />
            <Collapse in={withdrawExpanded} timeout="auto" unmountOnExit>
              <ul style={{ padding: '0 24px' }}>
                <li>
                  If the operation is activated: all request approvals (not countersigned) for this project will be
                  withdrawn. Then, you can manually decline / change the allocation and again approve requests for each
                  individual person. Note that you won’t be able to agree on old terms with the investors.
                </li>
                <li>
                  If the operation is not activated: all request approvals will not be withdrawn, meaning you will be
                  able
                  to continue with contract signature on old terms (before the tokenomics change).
                </li>
              </ul>
            </Collapse>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Switch name="moveToNovation" checked={moveToNovation}
                  onChange={(event) => setMoveToNovation(event.target.checked)}
          />
          <div>
            <Typography>
              Move all countersigned contracts to Novation
            </Typography>
            <ExpandMore
              expand={novationExpanded}
              onClick={() => setNovationExpanded(!novationExpanded)}
              aria-expanded={novationExpanded}
              aria-label="Learn more"
            />
            <Collapse in={novationExpanded} timeout="auto" unmountOnExit>
              <ul style={{ padding: '0 24px' }}>
                <li>
                  If the operation is activated: all requests with countersigned contracts will be moved to Novation.
                  You
                  will
                  see the new Novation status for such requests. Please note that you will need to approve the new token
                  allocation for contract's under Novation.
                </li>
                <li>
                  If the operation is not activated: all requests with countersigned contracts will be in approved
                  status,
                  Novation will not be triggered. However, you can manually move the request to Novation on the request
                  details page.
                </li>
              </ul>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  </Modal>;

  const handleSave = () => {
    const isWithConfirm = false;

    if (isWithConfirm) {
      setIsOpenTokenomicsDialog(true);
    } else {
      handleSubmit();
    }
  };

  const { data: projectData, loading } = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      projectId: id
    },
    fetchPolicy: 'network-only',
    skip: !id
  });

  const { data: helloSignData, loading: helloSignLoading } = useQuery(GET_HS_TEMPLATES, {
    variables: { templateType: HsTemplateType.ProjectPRequestContract },
    fetchPolicy: 'network-only'
  });

  const [helloSignTemplates, setHelloSignTemplates] = useState([]);
  useEffect(() => {
    if (helloSignData) {
      setHelloSignTemplates(helloSignData.getHsTemplates);
    }
  }, [helloSignData]);

  useEffect(() => {
    let savedProjectData: typeof data = { ...data };

    if (projectData) {
      let project = projectData.getProjectById;

      for (const formField in savedProjectData) {
        // @ts-ignore
        savedProjectData[formField] = project[formField];
      }
      savedProjectData['status'] = project.status === ProjectStatusEnum.Active;
      setData(savedProjectData);
      setFormLoaded(true);
      setImgSrc(project.avatar ? `${process.env.REACT_APP_API_URL_REST}${project.avatar}` : '');
    }
  }, [projectData]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, inputRef, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: ['image/png', 'image/jpeg'],
    onDrop: acceptedFiles => setFiles(acceptedFiles),
    onDropRejected: () => {
      enqueueSnackbar('Wrong file type. Please upload the image in JPEG or PNG format.', { variant: 'error' });
    }
  });

  const [createProject, {
    data: dataCreate,
    loading: loadingProjectCreate,
    error: errorProjectCreate
  }] = useMutation(CREATE_PROJECT);

  const [updateProject, {
    data: dataUpdate,
    loading: loadingProjectUpdate,
    error: errorProjectUpdate
  }] = useMutation(UPDATE_PROJECT);

  const handleCancel = () => {
    navigate('/private/projects');
  };

  const handleDateChange = _handleDateChange('tokenGenerationDate');
  const onClearDate = () => {
    handleDateChange(null);
  };

  function onSubmit() {
    try {
      const project: ProjectInput = {
        title: data.title,
        description: data.description,
        tokenName: data.tokenName,
        tokenSymbol: data.tokenSymbol,
        whitepaperLink: data.whitepaperLink,
        status: data.status ? ProjectStatusEnum.Active : ProjectStatusEnum.Inactive,
        tokenGenerationDate: data.tokenGenerationDate,
        externalTemplateId: data.externalTemplateId,
        contributionWallet: data.contributionWallet
      };
      if (id) {
        updateProject({
          variables: {
            project: { ...project, projectId: id },
            avatar: files[0],
            rounds: {
              created: normalizeRounds(data.rounds, crudStatus.CREATED),
              updated: normalizeRounds(data.rounds, crudStatus.UPDATED),
              deleted: normalizeRounds(data.rounds, crudStatus.DELETED)
            },
            socials: {
              created: normalizeSocials(data.socials, crudStatus.CREATED),
              updated: normalizeSocials(data.socials, crudStatus.UPDATED),
              deleted: normalizeSocials(data.socials, crudStatus.DELETED)
            },
            crop: completedCrop,
            spacing: completedSpacing
          }
        }).then(() => {
          navigate('/private/projects');
        }).catch((error: any) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      } else {
        createProject({
          variables: {
            project,
            avatar: files[0],
            rounds: {
              created: normalizeRounds(data.rounds, crudStatus.CREATED)
            },
            socials: {
              created: normalizeSocials(data.socials, crudStatus.CREATED)
            },
            crop: completedCrop,
            spacing: completedSpacing
          }
        }).then(() => {
          navigate('/private/projects');
        }).catch((error: any) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      }
    } catch (e) {
    }
  }

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);

  function confirmDelete() {
    setIsOpenDeleteDialog(true);
  }

  const [deleteProject, {
    data: dataDelete,
    loading: loadingProjectDelete,
    error: errorProjectDelete
  }] = useMutation(DELETE_PROJECT);
  const handleDelete = () => {
    deleteProject({
      variables: {
        projectId: id
      }
    }).then(() => {
      navigate('/private/projects');
    }).catch((error: any) => {
      enqueueSnackbar('Internal server error', { ...{ variant: 'error' } });
    });
  };

  let defaultHelloSignTemplate = helloSignTemplates.length > 0 ? helloSignTemplates[0].templateId : '';

  useEffect(() => {
    if (defaultHelloSignTemplate) {
      setData({
        ...data,
        externalTemplateId: defaultHelloSignTemplate
      });
    }
  }, [defaultHelloSignTemplate]);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [imgSrc, setImgSrc] = useState('');
  const [newImgSrc, setNewImgSrc] = useState('');
  const [originalCrop, setOriginalCrop] = useState<PixelCrop | PercentCrop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | PercentCrop>();
  const [completedSpacing, setCompletedSpacing] = useState<CompletedSpacing>({});
  const [originalData, setOriginalData] = useState<OriginalData>();
  const [imageBitmap, setImageBitmap] = useState<ImageBitmap>();

  useEffect(() => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (files[0].size * Math.pow(10, -6) > 10) {
          inputRef.current.value = null;
          enqueueSnackbar('The image must not exceed 10MB size', { ...{ variant: 'error' } });
        } else {
          setNewImgSrc(reader.result.toString() || '');
          setIsOpenCropDialog(true);
        }
      });
      reader.readAsDataURL(files[0]);
    }
  }, [files]);

  const handleCancelCrop = useCallback(() => {
    setFiles([]);
    setIsOpenCropDialog(false);
  }, []);

  const handleSaveCrop = (originalCrop: PercentCrop, completedCrop: PercentCrop, spacing: CompletedSpacing, originalData: OriginalData, imageBitmap: ImageBitmap) => {
    setOriginalData(originalData);
    setImageBitmap(imageBitmap);
    setOriginalCrop(originalCrop);
    setCompletedCrop(completedCrop);
    setCompletedSpacing(spacing);
    setIsOpenCropDialog(false);
  };

  let dropZoneDimension = 168;
  if (isTablet) {
    dropZoneDimension = 132;
  }
  if (isPhone) {
    dropZoneDimension = 132;
  }

  useEffect(() => {
    if (originalCrop?.width && originalCrop?.height && previewCanvasRef.current) {
      canvasPreview(
        originalData,
        imageBitmap,
        previewCanvasRef.current,
        originalCrop,
        1,
        dropZoneDimension
      );
    }
  }, [originalCrop, dropZoneDimension, imageBitmap]);

  const imageContent = () => {
    let content = null;

    if (Boolean(originalCrop) && imageBitmap) {
      content = <>
        <Avatar sx={{
          width: dropZoneDimension,
          height: dropZoneDimension,
          backgroundColor: 'white'
        }}>
          <canvas
            ref={previewCanvasRef}
            style={{
              width: dropZoneDimension,
              height: dropZoneDimension
            }}
          />
        </Avatar>
        <div className="dropzone__upload">
          <div>{getIcon('upload')}</div>
          <Typography>Drop your image</Typography>
          <Typography>here, or <span style={{ color: '#4A4DF0' }}>browse</span></Typography>
        </div>
      </>;
    } else if (imgSrc) {
      content = <>
        <Avatar sx={{
          width: dropZoneDimension,
          height: dropZoneDimension,
          backgroundColor: 'white'
        }}>
          <img src={imgSrc}/>
        </Avatar>
        <div className="dropzone__upload">
          <div>{getIcon('upload')}</div>
          <Typography>Drop your image</Typography>
          <Typography>here, or <span style={{ color: '#4A4DF0' }}>browse</span></Typography>
        </div>
      </>;
    } else {
      content = <>
        <div>{getIcon('upload')}</div>
        <Typography>Drop your image</Typography>
        <Typography>here, or <span style={{ color: '#4A4DF0' }}>browse</span></Typography>
      </>;
    }

    return content;
  };

  const isEmptyDropzone = !imgSrc && !imageBitmap;
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    width: `${dropZoneDimension}px`,
    height: `${dropZoneDimension}px`,

    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  const focusedStyle = {
    borderColor: '#4A4DF0'
  };
  const acceptStyle = {
    borderColor: '#2BB596'
  };
  const rejectStyle = {
    borderColor: '#E2507A'
  };
  const loadedStyle = {
    borderColor: '#2BB596',
    borderStyle: 'solid'
  };
  const emptyDropzoneStyle = {
    borderWidth: 2,
    borderRadius: '50%',
    borderColor: '#BBBDC0',
    borderStyle: 'dashed',
    backgroundColor: '#F5F5F5'
  };
  const dropZoneStyle = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...(isEmptyDropzone && files.length > 0 ? loadedStyle : {}),
    ...(isEmptyDropzone ? emptyDropzoneStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
    isEmptyDropzone
  ]) as any;

  return (
    <Panel>
      {tokenomicsModal()}
      <Modal
        open={isOpenDeleteDialog}
        title="Delete Project?"
        renderActions={() => <>
          <Button onClick={() => setIsOpenDeleteDialog(false)} disabled={loadingProjectDelete}>No</Button>
          <Button onClick={handleDelete} disabled={loadingProjectDelete}>Yes</Button>
        </>}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            {`Are you sure you want to delete the project "${data.title}"?`}
          </Typography>
        </>
      </Modal>
      <CropImageModal
        isOpenCropDialog={isOpenCropDialog}
        setIsOpenCropDialog={setIsOpenCropDialog}
        handleCancel={handleCancelCrop}
        handleSave={handleSaveCrop}
        imgSrc={newImgSrc}
      />
      <div className="form">
        <div className="form__body">
          <Grid container spacing={{ md: 7, xs: 2 }}>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={{ sm: 4, xs: 2 }}>
                <Grid item sm={5} xs={12}>
                  <div className="dropzone" {...getRootProps({ style: dropZoneStyle })}>
                    <input {...getInputProps()} />
                    {
                      imageContent()
                    }
                  </div>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={{ md: 4, xs: 2 }}>
                    <Grid item xs={12}>
                      <TextField label="Name" name="title" required variant="outlined" fullWidth
                                 onChange={handleTextChange}
                                 error={errors.title && errors.title.length > 0}
                                 value={data.title}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField label="Token Name" name="tokenName" variant="outlined"
                                 fullWidth onChange={handleTextChange}
                                 value={data.tokenName}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField label="Token Symbol" name="tokenSymbol" variant="outlined"
                                 fullWidth onChange={handleTextChange}
                                 value={data.tokenSymbol}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Project description" name="description" variant="outlined" multiline rows={5}
                             fullWidth
                             onChange={handleTextChange} value={data.description}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Whitepaper Link" name="whitepaperLink" variant="outlined"
                             fullWidth onChange={handleTextChange} value={data.whitepaperLink}/>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id={'externalTemplateId'}>Hellosign Template ID</InputLabel>
                    {
                      <Select fullWidth
                              label="Hellosign Template ID"
                              labelId="externalTemplateId"
                              name="externalTemplateId"
                              value={data.externalTemplateId || defaultHelloSignTemplate}
                              onChange={handleTextChange}
                      >
                        {
                          helloSignTemplates.length > 0 && helloSignTemplates.map((template: any, index: number) => (
                            <MenuItem key={index} value={template.templateId}>
                              <Typography>{template.title}</Typography>
                            </MenuItem>)
                          )
                        }
                      </Select>
                    }
                  </FormControl>
                </Grid>
                <Grid item sm={5} xs={10}>
                  {
                    formLoaded && <DatePicker
                      label="TGE Date"
                      value={data.tokenGenerationDate}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      PopperProps={{
                        placement: 'bottom-start',
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 8]
                            }
                          }
                        ]
                      }}
                      inputFormat="dd.MM.yyyy"
                      mask="__.__.____"
                    />
                  }
                </Grid>
                <Grid item xs={1}
                      sx={{ display: 'flex' }}
                >
                  <div className="icon-button-wrapper">
                    <IconButton onClick={() => {
                      onClearDate();
                    }}>
                      <ClearIcon/>
                    </IconButton>
                  </div>

                </Grid>
                <Grid item sm={6} xs={12}/>
                <Grid item sm={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch name="status" onChange={handleCheckboxChange}
                              checked={!!data.status}/>
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={{ md: 4, xs: 2 }}>
                <Grid item xs={12}>
                  <TextField label="Contribution Wallet" name="contributionWallet" variant="outlined" fullWidth
                             onChange={handleTextChange} value={data.contributionWallet}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Social Media</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SocialList
                    data={data.socials}
                    errors={errors.socials}
                    handleAddSocial={handleAddSocial('socials')}
                    handleSocialChange={handleSocialChange('socials')}
                    handleSocialTitleChange={handleSocialTitleChange('socials')}
                    handleDeleteSocial={handleDeleteSocial('socials')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Rounds</Typography>
                </Grid>
                <Grid item xs={12}>
                  {data.rounds.map((item: any, index: number) => {
                    return (
                      item.status !== crudStatus.DELETED &&
                      <Grid container spacing={2} key={index} sx={{ marginBottom: '24px' }}>
                        <Grid item md={4} xs={6}>
                          <TextField name="title" label="Round Name" variant="outlined" fullWidth
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => onRoundChange(event, index)}
                                     value={item.title}
                                     error={roundErrors && roundErrors[index] && roundErrors[index].title && roundErrors[index].title.length > 0}
                          />
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <NumberInput name="price" label="Price" variant="outlined" fullWidth
                                       InputProps={{
                                         endAdornment: <InputAdornment
                                           position="end">{getIcon('dollar')}</InputAdornment>
                                       }}
                                       onChange={(event: ChangeEvent<HTMLInputElement>, value: number) => onRoundChange(event, index, value)}
                                       value={item.price}
                                       error={roundErrors && roundErrors[index] && roundErrors[index].price && roundErrors[index].price.length > 0}
                                       precision={4}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <NumberInput name="tokenCount" label="Tokens" variant="outlined" fullWidth
                                       onChange={(event: ChangeEvent<HTMLInputElement>, value: number) => onRoundChange(event, index, value)}
                                       value={item.tokenCount}
                                       error={roundErrors && roundErrors[index] && roundErrors[index].tokenCount && roundErrors[index].tokenCount.length > 0}
                          />
                        </Grid>
                        <Grid item xs={1}
                              sx={{ display: 'flex' }}
                        >
                          <div className="icon-button-wrapper">
                            <IconButton onClick={() => {
                              onRoundDelete(index);
                            }}>
                              <ClearIcon/>
                              {isPhone && <Typography style={{ marginLeft: '16px' }}>Delete</Typography>}
                            </IconButton>
                          </div>

                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item md={4} xs={12}>
                    <Grid item xs={12}>
                      <Button startIcon={<AddIcon/>}
                              onClick={handleAddRound('rounds')}
                              sx={{
                                textTransform: 'none',
                                fontSize: '13px'
                              }}
                      >Add round</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="form__buttons">
          <div className="button-group">
            <Button variant="outlined" sx={{ width: { xs: id ? '96px' : '140px', md: 'auto' } }}
                    onClick={handleCancel}>Cancel</Button>
            {!isPhone && id && <Button sx={{ width: { xs: '96px', md: 'auto' } }} variant="outlined"
                                       onClick={confirmDelete} color="error"
                                       disabled={loadingProjectDelete}
            >Delete</Button>
            }
          </div>
          {isPhone && id && <div className="button-group">
            <Button variant="outlined" sx={{ width: { xs: '96px', md: 'auto' } }} onClick={confirmDelete}
                    color="error">Delete</Button>
          </div>}
          <div className="button-group">
            <Button variant="contained" sx={{ width: { xs: id ? '96px' : '140px', md: 'auto' } }}
                    disabled={(loadingProjectCreate && !errorProjectCreate) || (loadingProjectUpdate && !errorProjectUpdate)}
                    onClick={handleSave}>Save</Button>
          </div>
        </div>
      </div>
    </Panel>
  );
};
