import { gql } from '@apollo/client';

export const UPGRADE_TO_PAID_PLAN = gql`
  mutation UpgradeToPaidPlan($recurringSubscriptionIdPaid: ID!) {
    activateRecurringSubscription(recurringSubscriptionId: $recurringSubscriptionIdPaid) {
      checkoutUrl
    }
  }
`;

export const DOWNGRADE_TO_FREE_TIER = gql`
  mutation DowngradeToFreeTier($recurringSubscriptionIdFree: ID!) {
    activateRecurringSubscription(recurringSubscriptionId: $recurringSubscriptionIdFree) {
      checkoutUrl
    }
  }
`;

export const FOLLOW_CRYPTO_ASSET = gql`
  mutation Mutation($cryptoCryptoAssetId: ID!) {
    followCryptoAsset(cryptoCryptoAssetId: $cryptoCryptoAssetId)
  }
`;

export const UNFOLLOW_CRYPTO_ASSET = gql`
  mutation Mutation($cryptoCryptoAssetId: ID!) {
    unfollowCryptoAsset(cryptoCryptoAssetId: $cryptoCryptoAssetId)
  }
`;
