import React, { FC, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../TabPanel/TabPanel';
import { Wallets } from './Wallets';
import { CryptoAssets } from './CryptoAssets';

export const WalletsTabPanel: FC<any> = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Tabs
            value={activeTab}
            onChange={handleActiveTabChange}
          >
            <Tab value={0} label="Crypto Assets" sx={{ textTransform: 'none' }}/>
            <Tab value={1} label="Wallets" sx={{ textTransform: 'none' }}/>
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={activeTab} index={0}>
          <CryptoAssets navigateToWallets={() => setActiveTab(1)}/>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Wallets/>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
