import { PropsWithChildren } from 'react';
import { CircularProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import './Panel.scss';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  [theme.breakpoints.up('md')]: {
    padding: '32px 24px'
  },
  [theme.breakpoints.down('md')]: {
    padding: '24px 24px'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px 16px'
  },
  maxWidth: '1280px'
}));

interface PanelProps {
  isLoading?: boolean,
  fullHeight?: boolean
}

export const Panel = (props: PropsWithChildren<PanelProps>) => {
  const {isLoading, fullHeight, children} = props
  return (
    <StyledPaper style={{height: fullHeight ? '100%' : 'auto'}}>
      {
        isLoading && <div className="preloader">
          <CircularProgress color="primary"/>
        </div>
      }
      {children}
    </StyledPaper>
  );
};
