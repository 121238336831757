import { gql } from '@apollo/client';

export const CREATE_CRYPTO_EVENT_SOURCE = gql`
  mutation addCryptoEventSource(
    $title: String!,
    $code: String!,
    $link: String!
  ) {
    addCryptoEventSource(title: $title, code: $code, link: $link) {
      cryptoEventSourceId
    }
  }
`;

export const UPDATE_CRYPTO_EVENT_SOURCE = gql`
  mutation updateCryptoEventSource(
    $cryptoEventSourceId: ID!,
    $title: String!,
    $code: String!,
    $link: String!
  ) {
    updateCryptoEventSource(cryptoEventSourceId: $cryptoEventSourceId, title: $title, code: $code, link: $link) {
      cryptoEventSourceId
      title
      code
      link
    }
  }
`;

export const DELETE_CRYPTO_EVENT_SOURCE = gql`
  mutation deleteCryptoEventSource($cryptoEventSourceId: ID!) {
    deleteCryptoEventSource(cryptoEventSourceId: $cryptoEventSourceId){
      cryptoEventSourceId
    }
  }
`;
