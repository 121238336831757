import {createUploadLink} from 'apollo-upload-client';
import {tokenStorage} from '../bundles/common/tokenStorage';
import localSchema from './local.schema.graphql';
import {localResolvers} from './local.resolvers';
import config from '../config';

import {ApolloClient, InMemoryCache, from, split} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

let refreshPromise: Promise<string> = null; // keep track of the current refresh request, don't call it if it's in progress

const fetchWithToken = (uri: string, options: RequestInit): Promise<Response> => {
  let originalResponse: Response = null;

  // Do not remove!
  if (tokenStorage.getAccessToken()) {
    (options.headers as any)['Authorization'] = `Bearer ${tokenStorage.getAccessToken()}`;
  }

  return fetch(uri, options)
    .then((response) => {
      originalResponse = response;
      return response.clone().json();
    })
    .then((json) => {


      if (
        json &&
        json.errors &&
        json.errors[0] &&
        json.errors[0].extensions &&
        json.errors[0].extensions.code === 'UNAUTHENTICATED'
      ) {
        if (!refreshPromise) {
          const params = {
            operationName: 'RefreshToken',
            query: `
									mutation RefreshToken {
										refreshToken
									}
								`
          };

          // Execute the re-authorization request and set the promise returned to this.refreshPromise
          refreshPromise = fetch(uri, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(params)
          })
            .then((refreshResponse) => refreshResponse.json())
            .then((refreshResponseJson) => {
              if (refreshResponseJson.data && refreshResponseJson.data.refreshToken) {
                const newToken = refreshResponseJson.data.refreshToken as string;

                tokenStorage.setAccessToken(newToken);
                return newToken;
              } else {
                tokenStorage.clearToken();
              }
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }

        return refreshPromise.then((newAccessToken) => {
          refreshPromise = null;

          // Set the authorization header on the original options parameter to the new access token we got
          (options.headers as any)['Authorization'] = `Bearer ${newAccessToken}`;

          // Return the promise from the new fetch (which should now have used an active access token)
          // If the initialRequest had errors, this fetch that is returned below is the final result.
          // console.log('>>> fetchWithToken fetch promise:', uri, options);
          return fetch(uri, options);
        });
      }

      // If there were no errors in the initialRequest, return original response.
      // console.log('>>> fetchWithToken return originalResponse');
      return originalResponse;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const httpLink = createUploadLink({
  uri: config.apiUrl,
  credentials: 'include',
  fetch: fetchWithToken
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.apiUrlWS,
    connectionParams: {
      authToken: tokenStorage.getAccessToken()
    }
  })
);

const link =
  typeof window !== 'undefined' && wsLink != null
    ? split(
      ({ query }) => {
        const def = getMainDefinition(query);
        return (
          def.kind === 'OperationDefinition' &&
          def.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    )
    : httpLink;

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  typeDefs: [localSchema],
  resolvers: localResolvers,
  link: from([errorLink, link])
});
