import { styled } from '@mui/material/styles';
import { Badge, BadgeProps } from '@mui/material';
import './NotificationBadge.scss';

export const NotificationBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '&.MuiBadge-root': {
    display: 'flex'
  },
  '& .MuiBadge-badge': {
    borderRadius: '50%',
    right: '-16px',
    top: 0,
    width: '24px',
    height: '24px',
    fontSize: '10px',
    backgroundColor: '#E2507A'
  }
}));

export const SidebarNotificationBadge = (props: any) => {
  const { value } = props;

  return (
    <div className="badge">{value}</div>
  );
};
